import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import {IAdasSubscription} from '../../_types/adassubscription';
import DialogTransition from '../DialogTransition/DialogTansition';
import {LoadingButton} from '@mui/lab';

interface IDeleteAdasSubscriptionDialogProps {
  busy: boolean;
  equipmentSerialNumber: string;
  subscription: IAdasSubscription;
  open: boolean;
  onClose: (equipmentSerialNumber: string, value: IAdasSubscription, cancel: boolean) => void;
}

const DeleteAdasSubscriptionDialog: React.FC<IDeleteAdasSubscriptionDialogProps> = ({
  busy, equipmentSerialNumber, subscription, open, onClose
}): React.JSX.Element => {
  const handleClose = (cancel: boolean) => {
    onClose(equipmentSerialNumber, subscription, cancel);
  };

  return (
    <Dialog TransitionComponent={DialogTransition} open={open}>
      <DialogTitle>Delete {subscription.Subscription_Name || 'UNKNOWN'} for {equipmentSerialNumber}?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will delete the <b>{subscription.Subscription_Name || 'UNKNOWN'}</b> subscription for the device {equipmentSerialNumber}.
        </DialogContentText>
        <DialogActions>
          <Button disabled={busy} onClick={() => handleClose(true)}>Cancel</Button>
          <LoadingButton loading={busy} color="error" onClick={() => handleClose(false)}>Delete</LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
};

export default DeleteAdasSubscriptionDialog;
