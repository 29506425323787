import React, { useState } from 'react';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { AttachFile } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { TNtfyNew } from '../../_types/ntfy';
import { NtfyReport } from '../../features/store/ntfy.slice';
import { useDispatch } from 'react-redux';

const Activate: React.FC = (): React.JSX.Element => {
  const [file, setFile]: any = useState();
  const [activationReady, setActivationReady] = useState(false);
  const [busy, setBusy] = useState(false);
  const dispatch = useDispatch<any>();

  function handleChange(event: any) {
    setFile(event.target.files[0])
  }

  function onUploadClick() {
    const url = '/activation_upload';

    const formData = new FormData();
    // @ts-ignore
    formData.append('activation', file);
    // @ts-ignore
    //formData.append('fileName', file.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      responseType: 'arraybuffer',
    };

    setBusy(true);

    // @ts-ignore
    axios.post(url, formData, config)
      .then((res) => {
        if (res.status === 200) {
          const blob = new Blob([res.data], {
            type: 'application/octet-stream'
          });

          const a = document.createElement('a');
          const url = URL.createObjectURL(blob);
          a.href = url;
          a.download = 'Activate.zip';
          document.body.appendChild(a);
          a.click();
          setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }, 0);

          setActivationReady(true);
          setBusy(false);
        }
      })
      .catch((err) => {
        const notification = TNtfyNew(err.message, 'error', 'long');
        dispatch(NtfyReport(notification));
        setBusy(false);
      });
  }

  return (
    <div style={{padding:'30px'}}>
      {
        !activationReady ? (
          <div style={{backgroundColor:'#D0D0D0', padding:'10px', borderRadius:'5px'}}>
            <Typography variant="h2">
              USB Activation
            </Typography>
            <Typography variant="body1">
              <ul style={{listStyleType:"none"}}>
                <li>1. Insert the USB flash drive into an available port on the computer.</li>
                <li>2. Click the paperclip icon below.</li>
                <li>3. Use the popup box to select the USB drive and then double-click the Activation.zip file.</li>
                <li>4. Click the green Activate button when it appears.</li>
                <li>
                  <TextField
                    value={file ? file.name : ''}
                    onKeyDown={() => false}
                    helperText={!file ? 'click the paperclip icon to choose a file' : ''}
                    variant="outlined"
                    type="text"
                    InputProps={{
                      endAdornment: (
                        <IconButton component="label">
                          <AttachFile />
                          <input
                            disabled={busy}
                            style={{display:"none"}}
                            type="file"
                            hidden
                            onChange={handleChange}
                            accept=".zip"
                          />
                        </IconButton>
                      ),
                    }}
                  />
                </li>
                {
                  file ? (
                    <li>
                      <LoadingButton loading={busy} style={{marginTop:'8px'}} variant="contained" size="large" color="success" onClick={onUploadClick}>
                        Activate
                      </LoadingButton>
                    </li>
                  ) : null
                }
              </ul>
            </Typography>
          </div>
        ) : (
          <div style={{backgroundColor:'#D0D0D0', padding:'10px', borderRadius:'5px'}}>
            <Typography variant="h2">
              Activation Ready
            </Typography>
            <Typography variant="body1">
              <ul style={{listStyleType:"none"}}>
                <li>1. Save the downloaded file as Activate.zip to the USB flash drive. You may need to copy the file from the downloads folder to the USB drive.</li>
                <li>2. Once the Activate.zip file is on the USB drive, eject it from the laptop.</li>
                <li>3. Navigate to the Activation page on the aligner and insert the USB drive into an available USB port.</li>
                <li>4. Follow the instructions on the aligner to complete the activation process.</li>
                <li>
                  <Button style={{marginTop:'8px'}} variant="contained" size="large" color="success" onClick={() => { setFile(null); setActivationReady(false); }}>
                    Activate Another
                  </Button>
                </li>
              </ul>
            </Typography>
          </div>
        )
      }
    </div>
  );
};

export default Activate;
