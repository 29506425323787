import React, { useEffect } from 'react';

type RoutingBackProps = {
  children: JSX.Element,
};

const RoutingBack = (props: RoutingBackProps) => {
    useEffect(() => {
      window.addEventListener('popstate', onBack);
      return () => {
          window.removeEventListener('popstate', onBack);
      }
    }, []);

    const onBack = () => {
      window.history.pushState({ name: 'browserBack' }, 'on browser back click', window.location.href);
    }

    return (
      <>
        {props.children}
      </>
    )
};

export default RoutingBack;