/**
 * @copyright Snapon Equipment
 */

import React from "react";

import {useDispatch, useSelector,} from 'react-redux'
// import { Button } from "@mui/material";

import { SnackbarProvider, useSnackbar } from 'notistack';

// import Toast from '../Toast/Toast'
import {
  // rdxNtfyShort, rdxNtfyLong, rdxNtfyClick,
  rdxNtfyList,
  // NtfyShown,
  NtfyShownBatch,
  rdxNtfyTimeoutShort,
  rdxNtfyHideInfo,
} from '../../store/ntfy.slice';
import { TNtfy, TNtfyType, TNtfyLife } from '../../../_types/ntfy';

import * as Time from '../../../utils/time'

import {CompPopup} from './popup'

import './popup.css'

declare module 'notistack' {
  interface VariantOverrides {
    // removes the default variants, if set to false
    // error: false;
    // warning: false;
    // info: false;
    // success: false;

    // adds `myCustomVariant` variant
    myCustomVariant: true;

    // adds `CompPopup` variant and specifies the
    // "extra" props it takes in options of `enqueueSnackbar`
    CompPopup: {
      type: TNtfyType,
      life: TNtfyLife,
    }
  }
}

interface IProps {
  children: any,
}

/**
 */
const NtfyProvider = ( props: IProps ) =>
{
  /**
   */
  return (
      <SnackbarProvider
          Components={{
            CompPopup,
          }}
          maxSnack={3}  // 3, using 1 can solve the issue of having only one notification
          anchorOrigin={ {
            horizontal: "right",
            vertical: "top"
          } }
          preventDuplicate={true}
      >
        <NtfyRdxHook>
          {
            props.children
          }
        </NtfyRdxHook>
      </SnackbarProvider>
  )
} // NtfyProvider

/**
 */
export default NtfyProvider

/**
 */
const NtfyRdxHook = ( props: IProps ) =>
{
  const dispatch = useDispatch<any>()

  const timeoutShortNtfy = useSelector( rdxNtfyTimeoutShort )
  const HideInfoNtfy = useSelector( rdxNtfyHideInfo )
  const listNtfy = useSelector( rdxNtfyList )

  const { enqueueSnackbar, } = useSnackbar();

  const [curNtfy, CurNtfy] = React.useState<TNtfy | null>( null )

  /**
   */
  React.useEffect( () => {
    (async () => {
      try
      {
        await Time.wait(timeoutShortNtfy)
        CurNtfy( null )
        return {}
      }
      catch( err )
      {
        return {err}
      }
    })()
  }, [curNtfy, timeoutShortNtfy] )

  /**
   */
  React.useEffect( () => {
    // ProcessNtfy( listNtfy, dispatch, enqueueSnackbar, curMsg, CurMsg )
    ProcessNtfy()
  }, [listNtfy] )

  /**
   */
      // const ProcessNtfy = React.useCallback( () => {
  const ProcessNtfy = () =>
      {
        try
        {
          let cur_ntfy = curNtfy
          let cntShown = 0

          for( let i = 0; i < listNtfy.length; ++i )
          {
            try
            {
              const x: TNtfy = listNtfy[i]

              if( x.shown )
              {
                ++cntShown
                return {} // already shown as notification
              }

              // dispatch( NtfyShown({ts: x.ts, val: true}) )

              if( cur_ntfy?.msg === x.msg && cur_ntfy?.t === x.t )
              {
                return {}
              }

              if( 'click' === x.life && HideInfoNtfy )
              {
                return {}
              }

              cur_ntfy = x
              CurNtfy( x )

              const cur_msg: string = x.msg

              enqueueSnackbar(
                  cur_msg,
                  {
                    key: x.ts,
                    variant: 'CompPopup',
                    persist: 'click' === x.life,
                    type: x.t,
                    // msg: x.msg,
                    life: x.life,
                  }
              )

              return {}
            }
            catch( err )
            {
              console.error( err )

              return {err}
            }
          } // for i

          if( cntShown !== listNtfy.length )
          {
            dispatch( NtfyShownBatch({list: listNtfy, val: true}) )
          }

          return {}
        }
        catch( err )
        {
          console.error( err )

          // return Promise.reject( err )
          return {err}
        }
      }
  // }, [enqueueSnackbar, closeSnackbar, listNtfy, curNtfy, CurNtfy] )

  /**
   */
  return (
      <>
        {
          props.children
        }
      </>
  )
} // NtfyRdxHook


