import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { IAdasPackageDef } from '../../_types/adaspackagedef';

interface IAdasPackagePickerProps {
  packageDefs: IAdasPackageDef[],
  onChange?: (value: number) => void;
}

const AdasPackagePicker: React.FC<IAdasPackagePickerProps> = ({packageDefs, onChange}): React.JSX.Element => {
  const [value, setValue] = useState(0);

  const handleChange = (value: number) => {
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <>
      <FormControl size="small" fullWidth>
        <InputLabel id="package-label">Subscription Package Name</InputLabel>
        <Select
          value={value}
          labelId="package-label"
          label="Subscription Package Name"
          onChange={(event) => handleChange(event.target.value as number)}
        >
          {
            packageDefs.map((cur) => {
              return (
                <MenuItem key={cur.Subscription_Id} value={cur.Subscription_Id}>{cur.Package}</MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
    </>
  );
}

export default AdasPackagePicker;
