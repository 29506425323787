import axios from 'axios';

export const searchAligner = (sSearchString: string, token: any): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    const options = {
      url: '/Modules/AlignerUtilities.aspx/SearchAligner',
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      data: {
        sSearchString,
      },
    };

    try {
      const res = await axios(options).then(response => response);
      if (res.status === 200) {
        const d = JSON.parse(res.data.d);
        resolve(d);
      } else {
        resolve([]);
      }
    } catch (err) {
      reject(err);
    }
  });
}
