import * as React from 'react';
import { styled } from '@mui/material/styles';
import {TableRow} from '@mui/material';
import {grey} from "@mui/material/colors";

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F0F0F0',
  },
}));

export default StyledTableRow;
