import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

const FetchContext = createContext({
  authAxios: null,
  isAdmin: false,
  canAdd: false,
});
const { Provider } = FetchContext;

const FetchProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const getAccessToken = useCallback(async () => {
    try {
      console.log('getAccessToken');

      const token = await getAccessTokenSilently();
      setAccessToken(token);

      console.log('access token set');

      const decodedToken = jwtDecode(token);
      setIsAdmin(decodedToken.permissions.includes('write:all'));
      setCanAdd(decodedToken.permissions.includes('create:all'));

    } catch (err) {
      console.log(err);
    }
  }, [getAccessTokenSilently]);

  useEffect(async () => {
    getAccessToken();
  }, [getAccessToken]);

  const authAxios = axios.create({baseURL: 'https://soecloud.com'});

  authAxios.interceptors.request.use(
    config => {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    },
    err => {
      return Promise.reject(err);
    }
  );

  authAxios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const code = error && error.response ? error.response.status : 0;
      if (code === 401 || code === 500) {
        return getAccessToken();
      } else {
        return Promise.reject(error);
      }
    }
  )

  return (
    <Provider value={{authAxios, isAdmin, canAdd}}>
      {children}
    </Provider>
  )
};

export { FetchContext, FetchProvider };
