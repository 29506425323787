import React, {useState} from 'react';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { AttachFile } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { TNtfyNew } from '../../_types/ntfy';
import { NtfyReport } from '../../features/store/ntfy.slice';
import { useDispatch } from 'react-redux';

const SystemInit: React.FC = (): React.JSX.Element => {
  const [file, setFile]: any = useState();
  const [initializationReady, setInitializationReady] = useState(false);
  const [busy, setBusy] = useState(false);
  const dispatch = useDispatch<any>();

  function handleChange(event: any) {
    setFile(event.target.files[0])
  }

  function onUploadClick() {
    const url = '/initialization_upload';

    const formData = new FormData();
    // @ts-ignore
    formData.append('initialization', file);
    // @ts-ignore
    //formData.append('fileName', file.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      responseType: 'arraybuffer',
    };

    setBusy(true);

    // @ts-ignore
    axios.post(url, formData, config)
      .then((res) => {
        if (res.status === 200) {
          setInitializationReady(true);
          setBusy(false);
        }
      })
      .catch((err) => {
        const notification = TNtfyNew(err.message, 'error', 'long');
        dispatch(NtfyReport(notification));
        setBusy(false);
      });
  }

  return (
    <div style={{padding:'30px'}}>
      {
        !initializationReady ? (
          <div style={{backgroundColor:'#D0D0D0', padding:'10px', borderRadius:'5px'}}>
            <Typography variant="h2">
              System Initialization
            </Typography>
            <Typography variant="body1">
              <ul style={{listStyleType:"none"}}>
                <li>1. Insert the USB flash drive into an available port on the computer.</li>
                <li>2. Click the paperclip icon below.</li>
                <li>3. Use the popup box to select the USB drive and then double-click the Initialization.zip file.</li>
                <li>4. Click the green Initialize button when it appears.</li>
                <li>
                  <TextField
                    value={file ? file.name : ''}
                    onKeyDown={() => false}
                    helperText={!file ? 'click the paperclip icon to choose a file' : ''}
                    variant="outlined"
                    type="text"
                    InputProps={{
                      endAdornment: (
                        <IconButton component="label">
                          <AttachFile />
                          <input
                            disabled={busy}
                            style={{display:"none"}}
                            type="file"
                            hidden
                            onChange={handleChange}
                            accept=".zip"
                          />
                        </IconButton>
                      ),
                    }}
                  />
                </li>
                {
                  file ? (
                    <li>
                      <LoadingButton loading={busy} style={{marginTop:'8px'}} variant="contained" size="large" color="success" onClick={onUploadClick}>
                        Initialize
                      </LoadingButton>
                    </li>
                  ) : null
                }
              </ul>
            </Typography>
          </div>
        ) : (
          <div style={{backgroundColor:'#D0D0D0', padding:'10px', borderRadius:'5px'}}>
            <Typography variant="h2">
              Initialization Complete
            </Typography>
            <Typography variant="body1">
              <div>You can remove the flash drive.</div>
              <div>To initialize another, click the button below.</div>
            </Typography>
            <Button style={{marginTop:'8px'}} variant="contained" size="large" color="success" onClick={() => { setFile(null); setInitializationReady(false); }}>
              Initialize Another
            </Button>
          </div>
        )
      }
    </div>
  );
};

export default SystemInit;
