import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import {IManifestSpec} from '../../_types/manifestspec';
import DialogTransition from '../DialogTransition/DialogTansition';
import {LoadingButton} from '@mui/lab';

interface IDeleteManifestSpecDialogProps {
  busy: boolean;
  equipmentSerialNumber: string;
  manifestSpec: IManifestSpec;
  open: boolean;
  onClose: (equipmentSerialNumber: string, manifestSpec: IManifestSpec, cancel: boolean) => void;
}

const DeleteManifestSpecDialog: React.FC<IDeleteManifestSpecDialogProps> = ({
  busy, equipmentSerialNumber, manifestSpec, open, onClose
}): React.JSX.Element => {
  const handleClose = (cancel: boolean) => {
    onClose(equipmentSerialNumber, manifestSpec, cancel);
  };

  return (
    <Dialog TransitionComponent={DialogTransition} open={open}>
      <DialogTitle>Delete {manifestSpec.Spec_Name || 'UNKNOWN'} for {equipmentSerialNumber}?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will delete the <b>{manifestSpec.Spec_Name || 'UNKNOWN'}</b> specification database for the device {equipmentSerialNumber}.
        </DialogContentText>
        <DialogActions>
          <Button disabled={busy} onClick={() => handleClose(true)}>Cancel</Button>
          <LoadingButton loading={busy} color="error" onClick={() => handleClose(false)}>Delete</LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
};

export default DeleteManifestSpecDialog;
