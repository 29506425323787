import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import DialogTransition from '../DialogTransition/DialogTansition';
import { LoadingButton } from '@mui/lab';

interface IAddEquipmentDialogProps {
  open: boolean;
  busy: boolean;
  onClose: (birthCertificateJson: object | null, cancel: boolean) => void;
}

const AddEquipmentDialog: React.FC<IAddEquipmentDialogProps> = ({open, busy, onClose}): React.JSX.Element => {
  const [birthCertificateJson, setBirthCertificateJson] = useState(null);
  const [validJson, setValidJson] = useState(false);

  const onBirthCertificateChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    try {
      const bcJson = JSON.parse(event.target.value);
      setValidJson(true);
      setBirthCertificateJson(bcJson);
    } catch {
      setValidJson(false);
      setBirthCertificateJson(null);
    }
  }

  const handleClose = (cancel: boolean) => {
    onClose(birthCertificateJson, cancel);
  };

  const canAdd = () => {
    return validJson;
  };

  return (
    <Dialog TransitionComponent={DialogTransition} open={open}>
      <DialogTitle>Add Equipment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will add equipment based on the contents of the supplied birth certificate.  Paste the contents a birth certificate file into the box below.
        </DialogContentText>
        <TextField
          style={{width:'100%', margin:'15px 0 0 0'}}
          id="outlined-multiline-static"
          label="Birth Certificate JSON"
          multiline
          rows={10}
          variant="outlined"
          onChange={onBirthCertificateChange}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={busy} onClick={() => handleClose(true)}>Cancel</Button>
        <LoadingButton loading={busy} disabled={!canAdd()} color="error" onClick={() => handleClose(false)}>Save</LoadingButton>
      </DialogActions>
    </Dialog>
  )
};

export default AddEquipmentDialog;
