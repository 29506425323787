import React, {useContext, useEffect, useState} from 'react';
import { TNtfyNew } from '../../_types/ntfy';
import { useDispatch } from 'react-redux';
import { NtfyReport } from '../../features/store/ntfy.slice';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
  TablePagination,
} from '@mui/material';
import { IAdasSubscription } from '../../_types/adassubscription';
import EditAdasSubscriptionDialog from '../EditAdasSubscriptionDialog/EditAdasSubscriptionDialog';
import DeleteAdasSubscriptionDialog from '../DeleteAdasSubscriptionDialog/DeleteAdasSubscriptionDialog';
import NewAdasSubscriptionDialog from '../NewAdasSubscriptionDialog/NewAdasSubscriptionDialog';
import { IAdasPackageDef } from '../../_types/adaspackagedef';
import { IAdasSubscriptionOut } from '../../_types/adassubscriptionout';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import StyledTableRow from '../StyledTableRow/StyledTableRow';
import { FetchContext } from '../../features/contexts/fetch';

const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'Subscription_Name',
    numeric: false,
    disablePadding: true,
    label: 'Subscription Package Name',
  },
  {
    id: 'Start_Month',
    numeric: true,
    disablePadding: true,
    label: 'Start Month',
  },
  {
    id: 'YearIndex',
    numeric: true,
    disablePadding: true,
    label: 'Start Year',
  },
  {
    id: 'NumberDays',
    numeric: true,
    disablePadding: true,
    label: 'Mode Length',
  },
  {
    id: 'Mode',
    numeric: false,
    disablePadding: true,
    label: 'Mode',
  },
];

interface IAdasSubscriptionDetailProps {
  equipmentSerialNumber: string;
}

const AdasSubscriptionDetail: React.FC<IAdasSubscriptionDetailProps> = ({
  equipmentSerialNumber,
}): React.JSX.Element => {
  const [curMonth] = useState(new Date().getMonth()+1);
  const [curYear] = useState(new Date().getFullYear());
  const [busy, setBusy] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [adasSubscriptions, setAdasSubscriptions] = useState<IAdasSubscription[]>([]);
  const [packageDefs, setPackageDefs] = useState<IAdasPackageDef[]>([]);
  const [deleteAdasSubscriptionDialogOpen, setDeleteAdasSubscriptionDialogOpen] = React.useState(false);
  const [editAdasSubscriptionDialogOpen, setEditAdasSubscriptionDialogOpen] = React.useState(false);
  const [newAdasSubscriptionDialogOpen, setNewAdasSubscriptionDialogOpen] = React.useState(false);
  const [selectedSubscription, setSelectedSubscription] = React.useState({});
  const dispatch = useDispatch<any>();

  const fetchContext = useContext(FetchContext);

  useEffect(() => {
    //return () => {
      void (async () => {
        try {
          // @ts-ignore
          const { status, data } = await fetchContext.authAxios.post(
            '/Modules/AlignerUtilities.aspx/GetManifestAdasPackageListForUI',
            {
            }
          );

          if (status === 200) {
            const d = JSON.parse(data.d);
            setPackageDefs(d);
          }
        } catch (err: any) {
          const notification = TNtfyNew(
            err.message,
            'error',
            'long',
          );

          dispatch(NtfyReport(notification));

          setPackageDefs([]);
        }
      })();
    //}
  }, []);

  useEffect(() => {
    if (equipmentSerialNumber) {
      void (async () => {
        try {
          // @ts-ignore
          const { status, data } = await fetchContext.authAxios.post(
              '/Modules/AlignerManifest.aspx/ReadAdasSubscriptionBySerialNumber',
              {
                sAlignerSerialNumber: equipmentSerialNumber,
              }
          );

          if (status === 200) {
            const d = JSON.parse(data.d);

            if (d.Table) {
              const t = d.Table.map((cur: IAdasSubscription) => {
                const m = cur.MonthIndex || 1;
                cur.Start_Month = Months[m-1];

                // @ts-ignore
                cur['Subscription_Name'] = packageDefs ? packageDefs.find(f => f.Subscription_Id === cur.Subscription_Id)['Package'] : 'ERROR';

                cur['Mode'] = cur['Days0Weeks1Months2Mode'] === '0' ? 'Days' : cur['Days0Weeks1Months2Mode'] === '1' ? 'Weeks' : 'Months';
                return cur;
              });

              setAdasSubscriptions(t);
            } else {
              const notification = TNtfyNew(
                'Equipment serial number not found.',
                'info',
                'short',
              );

              dispatch(NtfyReport(notification));

              setAdasSubscriptions([]);
            }

          }
        } catch (err: any) {
          const notification = TNtfyNew(
            err.message,
            'error',
            'long',
          );

          dispatch(NtfyReport(notification));

          setAdasSubscriptions([]);
        }
      })();
    } else {
      setAdasSubscriptions([]);
    }
  }, [equipmentSerialNumber]);

  const onEditClick = (subscription: IAdasSubscription) => {
    setSelectedSubscription(subscription);
    setEditAdasSubscriptionDialogOpen(true);
  }

  const onDeleteClick = (subscription: IAdasSubscription) => {
    setSelectedSubscription(subscription);
    setDeleteAdasSubscriptionDialogOpen(true);
  }

  const onNewClick = () => {
    setNewAdasSubscriptionDialogOpen(true);
  }

  const handleDeleteAdasSubscriptionDialogClose = async (serialNumber: string, subscription: IAdasSubscription, cancel: boolean) => {
    if (!cancel && subscription.Subscription_Id) {
      setBusy(true);

      try {
        // @ts-ignore
        const { status, data } = await fetchContext.authAxios.post(
          '/Modules/AlignerManifest.aspx/DeleteAdasSubscription',
          {
            sAlignerSerialNumber: equipmentSerialNumber,
            sSubscriptionNumber: subscription.Subscription_Id,
          }
        );

        if (status === 200) {
          if (data.d.indexOf('PASS') > 0) {
            setAdasSubscriptions(adasSubscriptions.filter(cur => cur.Subscription_Id !== subscription.Subscription_Id));
          }
        }
      } catch(err: any) {
        console.log(`deleteAdasSubscription error: ${err.message}`);
      }
    }

    setBusy(false);
    setDeleteAdasSubscriptionDialogOpen(false);
  };

  const handleEditAdasSubscriptionDialogClose = async (serialNumber: string, subscription: IAdasSubscription, cancel: boolean) => {
    if (!cancel && subscription.Subscription_Id) {
      setBusy(true);

      try {
        const m = subscription.MonthIndex || 1;
        subscription.Start_Month = Months[m-1];
        // @ts-ignore
        subscription['Subscription_Name'] = packageDefs ? packageDefs.find(f => f.Subscription_Id === subscription.Subscription_Id)['Package'] : 'ERROR';
        subscription['Mode'] = subscription['Days0Weeks1Months2Mode'] === '0' ? 'Days' : subscription['Days0Weeks1Months2Mode'] === '1' ? 'Weeks' : 'Months';

        const subscriptionOut: IAdasSubscriptionOut = {
          sAlignerSerialNumber: equipmentSerialNumber,
          iSubscriptionNumber:  subscription.Subscription_Id as number,
          sMonth: `${subscription.MonthIndex}`,
          sYear: `${subscription.YearIndex}`,
          iNumberOfDays: subscription.NumberDays as number,
          iMode: parseInt(subscription.Days0Weeks1Months2Mode as string),
        };

        // @ts-ignore
        const { status, data } = await fetchContext.authAxios.post(
            '/Modules/AlignerManifest.aspx/InsertOrUpdateAdasSubscription',
            subscriptionOut
        );

        if (status === 200) {
          if (data.d.indexOf('PASS') > 0) {
            const index = adasSubscriptions.findIndex(cur => cur.Subscription_Id === subscription.Subscription_Id);

            adasSubscriptions[index] = subscription;
            setAdasSubscriptions(adasSubscriptions);
          }
        }
      } catch(err: any) {
        console.log(`insertOrUpdateAdasSubscription error: ${err.message}`);
      }
    }

    setBusy(false);
    setEditAdasSubscriptionDialogOpen(false);
    setSelectedSubscription({});
  };

  const handleNewAdasSubscriptionDialogClose = async (serialNumber: string, subscription: IAdasSubscription, cancel: boolean) => {
    if (!cancel && subscription.Subscription_Id && subscription.MonthIndex && subscription.YearIndex && subscription.NumberDays && subscription.Days0Weeks1Months2Mode) {
      setBusy(true);

      try {
        const m = subscription.MonthIndex || 1;
        subscription.Start_Month = Months[m-1];
        // @ts-ignore
        subscription['Subscription_Name'] = packageDefs ? packageDefs.find(f => f.Subscription_Id === subscription.Subscription_Id)['Package'] : 'ERROR';
        subscription['Mode'] = subscription['Days0Weeks1Months2Mode'] === '0' ? 'Days' : subscription['Days0Weeks1Months2Mode'] === '1' ? 'Weeks' : 'Months';

        const subscriptionOut: IAdasSubscriptionOut = {
          sAlignerSerialNumber: equipmentSerialNumber,
          iSubscriptionNumber:  subscription.Subscription_Id,
          sMonth: `${subscription.MonthIndex}`,
          sYear: `${subscription.YearIndex}`,
          iNumberOfDays: subscription.NumberDays as number,
          iMode: parseInt(subscription.Days0Weeks1Months2Mode as string),
        };

        // @ts-ignore
        const { status, data } = await fetchContext.authAxios.post(
            '/Modules/AlignerManifest.aspx/InsertOrUpdateAdasSubscription',
            subscriptionOut
        );

        if (status === 200) {
          if (data.d.indexOf('PASS') > 0) {
            const result = JSON.parse(data.d);
            subscription.id = result.id;
            adasSubscriptions.push(subscription);
            setAdasSubscriptions(adasSubscriptions);
          }
        }
      } catch(err: any) {
        console.log(`insertOrUpdateAdasSubscription error: ${err.message}`);
      }
    }

    setBusy(false);
    setNewAdasSubscriptionDialogOpen(false);
  };

  const getFieldValue = (subscription: IAdasSubscription, field: string) => {
    const value = subscription[field as keyof typeof subscription];

    return (
      <>
        {value}
      </>
    );
  }

  const getTableRow = (subscription: IAdasSubscription) => {
    return (
      <>
        {
          headCells.map(headCell => (
            <TableCell key={`${subscription.Subscription_Id}-${headCell.label}`}>
              {getFieldValue(subscription, headCell.id)}
            </TableCell>
          ))
        }
        <TableCell>
          {
            fetchContext.isAdmin ? (
              <>
                <Button size="small" style={{marginRight:'10px'}} variant="outlined" onClick={() => onEditClick(subscription)}>Edit</Button>
                <Button size="small" color="error" variant="outlined" onClick={() => onDeleteClick(subscription)}>Delete</Button>
              </>
            ) : null
          }
        </TableCell>
      </>
    );
  }

  const getFilteredPackageList = () => {
    return packageDefs.filter((cur) => {
      return !adasSubscriptions.some(obj => obj.Subscription_Id === cur.Subscription_Id);
    });
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{border:'1px solid blue',borderRadius:'5px',padding:'20px'}}>
      {
        (adasSubscriptions.length > 0 || equipmentSerialNumber !== '') ? (
          <Grid container spacing={2}>
            <Typography variant="h6" align="left" sx={{width:'50%'}}>
              <div style={{margin: '10px 0 0 15px'}}>
                Subscriptions
              </div>
            </Typography>
            <Typography align="right" sx={{width:'50%'}}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                component="div"
                count={adasSubscriptions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {
                      headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                        >
                          {headCell.label}
                        </TableCell>
                      ))
                    }
                    <TableCell>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    (rowsPerPage > 0
                      ? adasSubscriptions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : adasSubscriptions
                    ).map((subscription) => (
                      <StyledTableRow key={subscription.Subscription_Id}>
                        {
                          getTableRow(subscription)
                        }
                      </StyledTableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <Typography align="right" sx={{width:'100%'}}>
              {
                fetchContext.isAdmin ? (
                  <Button size="small" variant="outlined" style={{marginTop:'15px'}} onClick={() => onNewClick()}>New</Button>
                ) : null
              }
            </Typography>
          </Grid>
        ) : null
      }
      <EditAdasSubscriptionDialog
        busy={busy}
        equipmentSerialNumber={equipmentSerialNumber}
        subscription={Object.assign({}, selectedSubscription)}
        open={editAdasSubscriptionDialogOpen}
        onClose={handleEditAdasSubscriptionDialogClose}
      />
      <DeleteAdasSubscriptionDialog
        busy={busy}
        equipmentSerialNumber={equipmentSerialNumber}
        subscription={selectedSubscription}
        open={deleteAdasSubscriptionDialogOpen}
        onClose={handleDeleteAdasSubscriptionDialogClose}
      />
      <NewAdasSubscriptionDialog
        busy={busy}
        equipmentSerialNumber={equipmentSerialNumber}
        subscription={{MonthIndex: curMonth, YearIndex: curYear, NumberDays: 255, Days0Weeks1Months2Mode: '0'}}
        packageDefs={getFilteredPackageList()}
        open={newAdasSubscriptionDialogOpen}
        onClose={handleNewAdasSubscriptionDialogClose}
      />
    </div>
  );
};

export default AdasSubscriptionDetail;
