import React from 'react';
import {Typography} from "@mui/material";
import {hasLowerCase, hasUpperCase, hasNumber, hasSpecial, hasThreeOfFour} from "../../utils/passwordcomplexity";

interface IPasswordComplexityHintProps {
  password: string,
}

const PasswordComplexityHint: React.FC<IPasswordComplexityHintProps > = ({
 password
}): React.JSX.Element => {
  return (
    <div>
      <Typography variant="caption">
        {
          password.length >= 12 ? (
            <div style={{color:'green'}}>✓ At least 12 characters in length</div>
          ) : (
            <div style={{color:'red'}}>✕ At least 12 characters in length</div>
          )
        }
        {
          hasThreeOfFour(password) ? (
            <div style={{color:'green'}}>✓ Contains at least 3 of the following 4 types of characters:</div>
          ) :
          (
            <div style={{color:'red'}}>✕ Contains at least 3 of the following 4 types of characters:</div>
          )
        }
        {
          hasLowerCase(password) ? (
            <div style={{color:'green', padding:'0 0 0 15px'}}>✓ Lower case letters (a-z)</div>
          ) :
          (
            <div style={{color:'red', padding:'0 0 0 15px'}}>• Lower case letters (a-z)</div>
          )
        }
        {
          hasUpperCase(password) ? (
            <div style={{color:'green', padding:'0 0 0 15px'}}>✓ Upper case letters (A-Z)</div>
          ) :
          (
            <div style={{color:'red', padding:'0 0 0 15px'}}>• Upper case letters (A-Z)</div>
          )
        }
        {
          hasNumber(password) ? (
            <div style={{color:'green', padding:'0 0 0 15px'}}>✓ Numbers (0-9)</div>
          ) :
          (
            <div style={{color:'red', padding:'0 0 0 15px'}}>• Numbers (0-9)</div>
          )
        }
        {
          hasSpecial(password) ? (
            <div style={{color:'green', padding:'0 0 0 15px'}}>✓ Special characters (e.g. !@#$%^&*)</div>
          ) :
          (
            <div style={{color:'red', padding:'0 0 0 15px'}}>• Special characters (e.g. !@#$%^&*)</div>
          )
        }
      </Typography>
    </div>
  );
};

export default PasswordComplexityHint;
