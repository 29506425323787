import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import AlignmentsTable  from '../../components/AlignmentsTable/AlignmentsTable';
import { getAlignerByIDForAlignerPage } from '../../api/GetAlignerByIDForAlignerPage';
import { TNtfyNew } from '../../_types/ntfy';
import { NtfyReport } from '../../features/store/ntfy.slice';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import SearchIcon from '@mui/icons-material/Search';

const Alignments = () => {
  const [serialNumber, setSerialNumber] = useState('');
  const [equipmentSerialNumber, setEquipmentSerialNumber] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch<any>();

  const onEquipmentSerialNumberChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEquipmentSerialNumber(event.target.value.toUpperCase());
  }

  const onEquipmentSerialNumberKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'NumpadEnter'].includes(event.key)) {
      try {
        const token = await getAccessTokenSilently();
        await getAlignerByIDForAlignerPage(equipmentSerialNumber, token);
        setSerialNumber(equipmentSerialNumber);
      } catch (err: any) {
        const notification = TNtfyNew(
          err.message,
          'error',
          'long',
        );

        dispatch(NtfyReport(notification));
        setSerialNumber('');
      }
    }
  }

  return (
    <div style={{margin: '35px 30px 0px 30px'}}>
      <TextField
        style={{minWidth:'300px'}}
        id="outlined-basic"
        label="Equipment Serial Number"
        variant="outlined"
        value={equipmentSerialNumber}
        onChange={onEquipmentSerialNumberChange}
        onKeyDown={onEquipmentSerialNumberKeyPress}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" color="primary" onClick={() => setSerialNumber(equipmentSerialNumber)}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div style={{margin: '15px 0 30px 0'}}>
        <AlignmentsTable equipmentSerialNumber={serialNumber} />
      </div>
    </div>
  );
}

export default Alignments;
