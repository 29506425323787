import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import DialogTransition from '../DialogTransition/DialogTansition';
import {IAdasPackageDef} from "../../_types/adaspackagedef";
import AdasPackagePicker from '../AdasPackagePicker/AdasPackagePicker';
import MonthPicker from '../MonthPicker/MonthPicker';
import YearPicker from '../YearPicker/YearPicker'
import ModeLengthPicker from '../ModeLengthPicker/ModeLengthPicker';
import ModePicker from '../ModePicker/ModePicker';
import {IAdasSubscription} from "../../_types/adassubscription";

interface INewAdasSubscriptionDialogProps {
  busy: boolean;
  equipmentSerialNumber: string;
  subscription: IAdasSubscription,
  packageDefs: IAdasPackageDef[];
  open: boolean;
  onClose: (equipmentSerialNumber: string, subscription: IAdasSubscription, cancel: boolean) => void;
}

const NewAdasSubscriptionDialog: React.FC<INewAdasSubscriptionDialogProps> = ({
  busy, equipmentSerialNumber, subscription, packageDefs, open, onClose
}): React.JSX.Element => {
  const [canCreate, setCanCreate] = useState<boolean>(false);

  useEffect(() => {
    checkCanCreate()
  }, [open]);

  const handleClose = (cancel: boolean) => {
    onClose(equipmentSerialNumber, subscription, cancel);
  };

  const onAdasPackageChange = (value: number) => {
    subscription.Subscription_Id = value;
    checkCanCreate();
  };

  const onMonthChange = (value: number) => {
    subscription.MonthIndex = value;
    checkCanCreate();
  }

  const onYearChange = (value: number) => {
    subscription.YearIndex = value;
    checkCanCreate();
  }

  const onModeLengthChange = (value: number) => {
    subscription.NumberDays = value;
    checkCanCreate();
  }

  const onModeChange = (value: string) => {
    subscription.Days0Weeks1Months2Mode = value;
    checkCanCreate();
  }

  const checkCanCreate = () => {
    const result = (
      subscription.Subscription_Id !== undefined
      && subscription.MonthIndex !== undefined
      && subscription.YearIndex !== undefined
      && subscription.NumberDays !== undefined
      && subscription.Days0Weeks1Months2Mode !== undefined
    );

    setCanCreate(result);
  }

  return (
    <Dialog TransitionComponent={DialogTransition} open={open}>
      <DialogTitle>New subscription for {equipmentSerialNumber}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select the values for the new subscription.
        </DialogContentText>
        <div style={{margin:'15px 0 0 0'}}>
          <AdasPackagePicker packageDefs={packageDefs} onChange={onAdasPackageChange}/>
        </div>
        <div style={{margin:'15px 0 0 0'}}>
          <MonthPicker initialValue={subscription.MonthIndex} onChange={onMonthChange}/>
        </div>
        <div style={{margin:'15px 0 0 0'}}>
          <YearPicker initialValue={subscription.YearIndex} onChange={onYearChange}/>
        </div>
        <div style={{margin:'15px 0 0 0'}}>
          <ModeLengthPicker initialValue={subscription.NumberDays} onChange={onModeLengthChange}/>
        </div>
        <div style={{margin:'15px 0 0 0'}}>
          <ModePicker initialValue={parseInt(subscription.Days0Weeks1Months2Mode as string)} onChange={onModeChange}/>
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={busy} onClick={() => handleClose(true)}>Cancel</Button>
        <LoadingButton disabled={!canCreate} loading={busy} color="success" onClick={() => handleClose(false)}>Create</LoadingButton>
      </DialogActions>
    </Dialog>
  )
};

export default NewAdasSubscriptionDialog;
