import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import { TNtfyNew } from '../../_types/ntfy';
import { useDispatch } from 'react-redux';
import { NtfyReport } from '../../features/store/ntfy.slice';
import { IEquipment } from '../../_types/equipment';
import { Grid } from '@mui/material';
import { FetchContext } from '../../features/contexts/fetch';

interface IEquipmentDetailProps {
  equipmentSerialNumber: string;
}

const EquipmentDetail: React.FC<IEquipmentDetailProps> = ({
  equipmentSerialNumber,
}): React.JSX.Element => {
  const [equipment, setEquipment] = useState<IEquipment>({});
  const dispatch = useDispatch<any>();

  const fetchContext = useContext(FetchContext);

  useEffect(() => {
    if (equipmentSerialNumber) {
      void (async () => {
        try {
          // @ts-ignore
          const { status, data } = await fetchContext.authAxios.post(
             '/Modules/AlignerUtilities.aspx/GetAlignerByIDForAlignerPage',
             {
               sAlignerSerialNumber: equipmentSerialNumber,
             }
          );

          if (status === 200) {
            const d = JSON.parse(data.d);
            if (d.length === 1) {
              setEquipment(d[0]);
            } else if (d.length === 0) {
              throw(new Error('Equipment serial number not found.'));
            } else {
              // this case shouldn't happen, it would mean more than one entry with the supplied serial number!!
            }
          }
        } catch (err: any) {
          const notification = TNtfyNew(
            err.message,
      'error',
       'long',
          );

          dispatch(NtfyReport(notification));

          setEquipment({});
        }
      })();
    } else {
      setEquipment({});
    }
  }, [equipmentSerialNumber]);

  const getValueForKey = (key: keyof typeof equipment) => {
    return (
      <>
        {'' || typeof(equipment[key]) === 'string' ? decodeURI(equipment[key] as string) : equipment[key]?.toString() }
      </>
    )
  };

  return (
    <div style={{border:'1px solid blue',borderRadius:'5px',padding:'20px'}}>
      <Grid container spacing={2}>
        {
          Object.keys(equipment).map(cur => (
            <>
              {
                cur !== 'ID' ? (
                <>
                  <Grid item={true} xs={6}>
                    {cur}
                  </Grid>
                  <Grid item={true} xs={6}>
                    {getValueForKey(cur as keyof typeof equipment)}
                  </Grid>
                </>
                ) : null
              }
            </>
          ))
        }
      </Grid>
    </div>
  );
};

export default EquipmentDetail;
