import React, {useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {IFeatureDef} from "../../_types/featuredef";

interface IAdasPackagePickerProps {
  featureDefs: IFeatureDef[],
  onChange?: (value: number) => void;
}

const ManifestFeaturePicker: React.FC<IAdasPackagePickerProps> = ({featureDefs, onChange}): React.JSX.Element => {
  const [value, setValue] = useState(0);

  const handleChange = (value: number) => {
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <>
      <FormControl size="small" fullWidth>
        <InputLabel id="feature-label">Feature</InputLabel>
        <Select
          value={value}
          labelId="feature-label"
          label="Feature"
          onChange={(event) => handleChange(event.target.value as number)}
        >
          {
            featureDefs.map((cur) => {
              return (
                <MenuItem key={cur.id} value={cur.id}>{cur.Name}</MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
    </>
  );
}

export default ManifestFeaturePicker;
