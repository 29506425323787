import React, {useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {ISpecDef} from "../../_types/specdef";

interface IAdasPackagePickerProps {
  specDefs: ISpecDef[],
  onChange?: (value: number) => void;
}

const ManifestSpecPicker: React.FC<IAdasPackagePickerProps> = ({specDefs, onChange}): React.JSX.Element => {
  const [value, setValue] = useState(0);

  const handleChange = (value: number) => {
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <>
      <FormControl size="small" fullWidth>
        <InputLabel id="database-label">Database</InputLabel>
        <Select
          value={value}
          labelId="database-label"
          label="Database"
          onChange={(event) => handleChange(event.target.value as number)}
        >
          {
            specDefs.map((cur) => {
              return (
                <MenuItem key={cur.id} value={cur.id}>{cur.Name}</MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
    </>
  );
}

export default ManifestSpecPicker;
