import React, {useContext, useEffect, useState} from 'react';
import { TNtfyNew } from '../../_types/ntfy';
import { useDispatch } from 'react-redux';
import { NtfyReport } from '../../features/store/ntfy.slice';
import { IManifestSpec } from '../../_types/manifestspec';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button, Typography, TablePagination
} from '@mui/material';
import { ISpecDef } from '../../_types/specdef';
import DeleteManifestSpecDialog from '../DeleteManifestSpecDialog/DeleteManifestSpecDialog';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import NewManifestSpecDialog from '../NewManifestSpecDialog/NewManifestSpecDialog';
import StyledTableRow from '../StyledTableRow/StyledTableRow';
import EditManifestSpecDialog from '../EditManifestSpecDialog/EditManifestSpecDialog';
import { IManifestSpecOut } from '../../_types/manifestspecout';
import { FetchContext } from '../../features/contexts/fetch';

const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'Spec_Name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'NumberDays',
    numeric: true,
    disablePadding: true,
    label: 'Days',
  },
  {
    id: 'End_Month',
    numeric: false,
    disablePadding: true,
    label: 'End Month',
  },
  {
    id: 'YearIndex',
    numeric: false,
    disablePadding: true,
    label: 'End Year',
  },
  {
    id: 'Mode',
    numeric: false,
    disablePadding: true,
    label: 'Mode',
  },
];

interface ISpecManifestDetailProps {
  equipmentSerialNumber: string;
}

const SpecManifestDetail: React.FC<ISpecManifestDetailProps> = ({
  equipmentSerialNumber,
}): React.JSX.Element => {
  const [curMonth] = useState(new Date().getMonth()+1);
  const [curYear] = useState(new Date().getFullYear());
  const [busy, setBusy] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [specDefs, setSpecDefs] = useState<ISpecDef[]>([]);
  const [manifestSpecs, setManifestSpecs] = useState<IManifestSpec[]>([]);
  const [deleteManifestSpecDialogOpen, setDeleteManifestSpecDialogOpen] = React.useState(false);
  const [selectedManifestSpec, setSelectedManifestSpec] = React.useState({});
  const [editManifestSpecDialogOpen, setEditManifestSpecDialogOpen] = React.useState(false);
  const [newManifestSpecDialogOpen, setNewManifestSpecDialogOpen] = React.useState(false);
  const dispatch = useDispatch<any>();

  const fetchContext = useContext(FetchContext);

  useEffect(() => {
    //return () => {
      void (async () => {
        try {
          // @ts-ignore
          const { status, data } = await fetchContext.authAxios.post(
            '/Modules/AlignerUtilities.aspx/GetManifestSpecsListForUI',
            {
            }
          );

          if (status === 200) {
            const d = JSON.parse(data.d)
            setSpecDefs(d);
          }
        } catch (err: any) {
          const notification = TNtfyNew(
            err.message,
            'error',
            'long',
          );

          dispatch(NtfyReport(notification));

          setSpecDefs([]);
        }
      })();
    //}
  }, []);

  useEffect(() => {
    if (equipmentSerialNumber) {
      void (async () => {
        try {
          // @ts-ignore
          const { status, data } = await fetchContext.authAxios.post(
              '/Modules/AlignerUtilities.aspx/GetManifestSpecificationsDataByAlignerForUI',
              {
                sAlignerSerialNumber: equipmentSerialNumber,
              }
          );

          if (status === 200) {
            const d = JSON.parse(data.d).map((cur: IManifestSpec) => {
              const m = cur.MonthIndex || 1;
              cur.End_Month = Months[m-1];

              // @ts-ignore
              cur['Spec_Name'] = specDefs ? specDefs.find(s => s.id === cur.Specification_Id)['Name'] : 'ERROR';
              cur['Mode'] = cur['Days0Weeks1Months2Mode'] === '0' ? 'Days' : cur['Days0Weeks1Months2Mode'] === '1' ? 'Weeks' : 'Months';

              return cur;
            }).sort((a: IManifestSpec, b: IManifestSpec) => {
              // @ts-ignore
              return a.Specification_Id_Id > b.Specification_Id;
            });
            setManifestSpecs(d);
          }
        } catch (err: any) {
          const notification = TNtfyNew(
            err.message,
            'error',
            'long',
          );

          dispatch(NtfyReport(notification));

          setManifestSpecs([]);
        }
      })();
    } else {
      setManifestSpecs([]);
    }
  }, [equipmentSerialNumber]);

  const onEditClick = (spec: IManifestSpec) => {
    setSelectedManifestSpec(spec);
    setEditManifestSpecDialogOpen(true);
  }

  const onDeleteClick = (spec: IManifestSpec) => {
    setSelectedManifestSpec(spec);
    setDeleteManifestSpecDialogOpen(true);
  }

  const onNewClick = () => {
    setNewManifestSpecDialogOpen(true);
  }

  const getFieldValue = (spec: IManifestSpec, field: string) => {
    const value = spec[field as keyof typeof spec]

    return (
      <>
        {value}
      </>
    )
  }

  const getTableRow = (spec: IManifestSpec) => {
    return (
      <>
        {
          headCells.map(headCell => (
            <TableCell key={`${spec.id}-${headCell.label}`}>
              {getFieldValue(spec, headCell.id)}
            </TableCell>
          ))
        }
        <TableCell>
          {
            fetchContext.isAdmin ? (
              <>
                <Button size="small" style={{marginRight:'10px'}} variant="outlined" onClick={() => onEditClick(spec)}>Edit</Button>
                <Button size="small" color="error" variant="outlined" onClick={() => onDeleteClick(spec)}>Delete</Button>
              </>
            ) : null
          }
        </TableCell>
      </>
    );
  }

  const handleDeleteManifestSpecDialogClose = async (serialNumber: string, manifestSpec: IManifestSpec, cancel: boolean) => {
    if (!cancel && manifestSpec.Specification_Id) {
      setBusy(true);

      try {
        // @ts-ignore
        const { status, data } = await fetchContext.authAxios.post(
            '/Modules/AlignerUtilities.aspx/DeleteManifestRecordForUI',
            {
              iRecordID: manifestSpec.id,
            }
        );

        if (status === 200) {
          if (data.d.indexOf('Success') >= 0) {
            setManifestSpecs(manifestSpecs.filter(cur => cur.id !== manifestSpec.id));
          }
        }
      } catch(err: any) {
        console.log(`deleteManifestRecordForUI error: ${err.message}`);
      }
    }

    setBusy(false);
    setDeleteManifestSpecDialogOpen(false);
  };

  const handleEditManifestSpecDialogClose = async (serialNumber: string, manifestSpec: IManifestSpec, cancel: boolean) => {
    if (!cancel && manifestSpec.Specification_Id) {
      setBusy(true);

      try {
        const m = manifestSpec.MonthIndex || 1;
        manifestSpec.End_Month = Months[m-1];

        // @ts-ignore
        manifestSpec['Spec_Name'] = specDefs ? specDefs.find(f => f.id === manifestSpec.Specification_Id)['Name'] : 'ERROR';
        manifestSpec['Mode'] = manifestSpec['Days0Weeks1Months2Mode'] === '0' ? 'Days' : manifestSpec['Days0Weeks1Months2Mode'] === '1' ? 'Weeks' : 'Months';

        const manifestSpecOut: IManifestSpecOut = {
          sAlignerSerialNumber: equipmentSerialNumber,
          iSpecsNumber: manifestSpec.Specification_Id as number,
          iNumberOfDays: manifestSpec.NumberDays as number,
          sMonth: `${manifestSpec.MonthIndex}`,
          sYear: `${manifestSpec.YearIndex}`,
          iMode: parseInt(manifestSpec.Days0Weeks1Months2Mode as string),
        };

        // @ts-ignore
        const { status, data } = await fetchContext.authAxios.post(
            '/Modules/AlignerManifest.aspx/InsertOrUpdateSpecs',
            manifestSpecOut
        );

        if (status === 200) {
          if (data.d.indexOf('PASS') > 0) {
            const index = manifestSpecs.findIndex(cur => cur.Specification_Id === manifestSpec.Specification_Id);

            manifestSpecs[index] = manifestSpec;
            setManifestSpecs(manifestSpecs);
          }
        }
      } catch(err: any) {
        console.log(`insertOrUpdateSpecs error: ${err.message}`);
      }
    }

    setBusy(false);
    setEditManifestSpecDialogOpen(false);
  }

  const handleNewManifestSpecDialogClose = async (serialNumber: string, manifestSpec: IManifestSpec, cancel: boolean) => {
    if (!cancel && manifestSpec.Specification_Id) {
      setBusy(true);

      try {
        const m = manifestSpec.MonthIndex || 1;
        manifestSpec.End_Month = Months[m-1];

        // @ts-ignore
        manifestSpec['Spec_Name'] = specDefs ? specDefs.find(f => f.id === manifestSpec.Specification_Id)['Name'] : 'ERROR';
        manifestSpec['Mode'] = manifestSpec['Days0Weeks1Months2Mode'] === '0' ? 'Days' : manifestSpec['Days0Weeks1Months2Mode'] === '1' ? 'Weeks' : 'Months';

        const manifestSpecOut: IManifestSpecOut = {
          sAlignerSerialNumber: equipmentSerialNumber,
          iSpecsNumber: manifestSpec.Specification_Id as number,
          iNumberOfDays: manifestSpec.NumberDays as number,
          sMonth: `${manifestSpec.MonthIndex}`,
          sYear: `${manifestSpec.YearIndex}`,
          iMode: parseInt(manifestSpec.Days0Weeks1Months2Mode as string),
        };

        // @ts-ignore
        const { status, data } = await fetchContext.authAxios.post(
            '/Modules/AlignerManifest.aspx/InsertOrUpdateSpecs',
            manifestSpecOut
        );

        if (status === 200) {
          if (data.d.indexOf('PASS') > 0) {
            const result = JSON.parse(data.d);
            manifestSpec.id = result.id;
            manifestSpecs.push(manifestSpec);
            setManifestSpecs(manifestSpecs);
          }
        }
      } catch(err: any) {
        console.log(`insertOrUpdateSpecs error: ${err.message}`);
      }
    }

    setBusy(false);
    setNewManifestSpecDialogOpen(false);
  }

  const getFilteredSpecList = () => {
    return specDefs.filter((cur) => {
      return !manifestSpecs.some(obj => obj.Specification_Id === cur.id);
    });
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{border:'1px solid blue', borderRadius:'5px', padding:'20px'}}>
      {
        (manifestSpecs.length > 0 || equipmentSerialNumber !== '') ? (
          <Grid container spacing={2}>
            <Typography variant="h6" align="left" sx={{width:'50%'}}>
              <div style={{margin: '10px 0 0 15px'}}>
                Specifications
              </div>
            </Typography>
            <Typography align="right" sx={{width:'50%'}}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                component="div"
                count={manifestSpecs.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {
                      headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                        >
                          {headCell.label}
                        </TableCell>
                      ))
                    }
                    <TableCell>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    (rowsPerPage > 0
                      ? manifestSpecs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : manifestSpecs
                    ).map((spec) => (
                      <StyledTableRow key={spec.id}>
                        {
                          getTableRow(spec)
                        }
                      </StyledTableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <Typography align="right" sx={{width:'100%'}}>
              {
                fetchContext.isAdmin ? (
                  <Button size="small" variant="outlined" style={{marginTop:'15px'}} onClick={() => onNewClick()}>New</Button>
                ) : null
              }
            </Typography>
          </Grid>
        ) : null
      }
      <EditManifestSpecDialog
        busy={busy}
        equipmentSerialNumber={equipmentSerialNumber}
        manifestSpec={Object.assign({}, selectedManifestSpec)}
        open={editManifestSpecDialogOpen}
        onClose={handleEditManifestSpecDialogClose}
      />
      <DeleteManifestSpecDialog
        busy={busy}
        equipmentSerialNumber={equipmentSerialNumber}
        manifestSpec={selectedManifestSpec}
        open={deleteManifestSpecDialogOpen}
        onClose={handleDeleteManifestSpecDialogClose}
      />
      <NewManifestSpecDialog
        busy={busy}
        equipmentSerialNumber={equipmentSerialNumber}
        manifestSpec={{ NumberDays: 255, MonthIndex: curMonth, YearIndex: curYear, Days0Weeks1Months2Mode: '0' }}
        specDefs={getFilteredSpecList()}
        open={newManifestSpecDialogOpen}
        onClose={handleNewManifestSpecDialogClose}
      />
    </div>
  );
};

export default SpecManifestDetail;
