import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  ListItem, ListItemText,
} from '@mui/material';
import DialogTransition from '../DialogTransition/DialogTansition';
import SearchIcon from '@mui/icons-material/Search';
import { TNtfyNew } from '../../_types/ntfy';
import { NtfyReport } from '../../features/store/ntfy.slice';
import { useDispatch } from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";
import { FixedSizeList } from 'react-window';
import { searchAligner } from '../../api/SearchAligner';

interface IEquipmentSearchDialogProps {
  open: boolean;
  onClose: () => void;
}

const EquipmentSearchDialog: React.FC<IEquipmentSearchDialogProps> = ({open, onClose}): React.JSX.Element => {
  const [busy, setBusy] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch<any>();

  const getDecodedString = (stringToDecode: string | null) => {
    try {
      return decodeURI(stringToDecode ? stringToDecode : '')
    } catch {
      return '';
    }
  }

  // @ts-ignore
  const Row = ({index, style}) => {
    // @ts-ignore
    const serialNumber = searchResults[index].UnitID;
    // @ts-ignore
    const version = searchResults[index].Version;
    // @ts-ignore
    const name = searchResults[index].ShopName;
    // @ts-ignore
    const address1 = searchResults[index].ShopAddress1;
    // @ts-ignore
    const address2 = searchResults[index].ShopAddress2;
    // @ts-ignore
    const phone = searchResults[index].ShopPhone;

    return (
      <ListItem style={{...style, margin: '0 0 5px 0'}} key={index} component="div" disablePadding>
        <ListItemText
          style={{backgroundColor: 'lightgray'}}
          primary={`${serialNumber} - ${version}`}
          secondary={
            <React.Fragment>
              <Typography variant="caption">
                <div style={{minHeight:'20px'}}>
                  {getDecodedString(name)}
                </div>
                <div style={{minHeight:'20px'}}>
                  {getDecodedString(address1)}
                </div>
                <div style={{minHeight:'20px'}}>
                  {getDecodedString(address2)}
                </div>
                <div style={{minHeight:'20px'}}>
                  {getDecodedString(phone)}
                </div>
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
    );
  }

  // @ts-ignore
  const Searching = ({index, style}) => {
    return (
      <ListItem style={{...style, margin: '0 0 5px 0'}} key={index} component="div" disablePadding>
        <ListItemText
          style={{backgroundColor: 'lightgray'}}
          primary={`Searching...`}
        />
      </ListItem>
    );
  }

  const onSearchStringChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchString(event.target.value);
  }

  const onSearchStringKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'NumpadEnter'].includes(event.key)) {
      setBusy(true);

      try {
        const token = await getAccessTokenSilently();
        const r = await searchAligner(searchString, token);
        setSearchResults(r);
      } catch (err: any) {
        const notification = TNtfyNew(
          err.message,
          'error',
          'long',
        );

        dispatch(NtfyReport(notification));

        setSearchResults([]);
      }

      setBusy(false);
    }
  }

  const onSearchClick = async () => {
    setBusy(true);

    try {
      const token = await getAccessTokenSilently();
      const r = await searchAligner(searchString, token);
      setSearchResults(r);
    } catch (err: any) {
      const notification = TNtfyNew(
        err.message,
        'error',
         'long',
      );

      dispatch(NtfyReport(notification));

      setSearchResults([]);
    }

    setBusy(false);
  }

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog TransitionComponent={DialogTransition} open={open} maxWidth="lg">
      <DialogTitle>Search Equipment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Search shop info for the specified string.
        </DialogContentText>
        <TextField
          disabled={busy}
          style={{margin:'15px 0 0 0', width:'100%'}}
          id="outlined-basic"
          label="Search String"
          variant="outlined"
          value={searchString}
          onChange={onSearchStringChange}
          onKeyDown={onSearchStringKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" color="primary" onClick={onSearchClick}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div style={{margin:'15px 0 0 0', border:'1px solid blue', borderRadius:'5px', padding: '5px'}}>
          {
            busy ? (
              <FixedSizeList
                height={300}
                width={800}
                itemSize={20}
                itemCount={1}
              >
                {Searching}
              </FixedSizeList>
            ) : (
              <FixedSizeList
                height={300}
                width={800}
                itemSize={105}
                itemCount={searchResults.length}
              >
                {Row}
              </FixedSizeList>
            )
          }
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EquipmentSearchDialog;
