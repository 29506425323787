/**
 * @copyright Snapon
 */


/**
 */
export type TNtfyType = 'info' | 'warn' | 'error' | 'success'

/**
 */
export type TNtfyMsg = string

/**
 * Lifespan of a notification. If its short or long, its managed with timeout.
 * If it is set to 'click', it waits for user action.
 * For custom (later) the timeout can be set to what it should be. It is for later implementation,
 * if required.
 */
export type TNtfyLife = 'short' | 'long' | 'click'

// /**
//  */
export type TNtfyLevel = 0 | 1 | 2 | 3 | 4 | 5

/**
 */
export type TNtfyCode = string | null

/**
 */
export type TNtfy = {
  ts: number,              // timestamp

  t: TNtfyType,             // notification type
  msg: TNtfyMsg,            // notification message

  life: TNtfyLife,          // lifespan
  code?: TNtfyCode,         // Notification code

  isLog?: boolean,          // log as well

  shown?: boolean,        // notification rendererd
  seen?: boolean,         // notification seen

  l: TNtfyLevel             // ...
} // TNtfy

/**
 */
export const TNtfyNew = ( msg: TNtfyMsg, type: TNtfyType, life: TNtfyLife, code: TNtfyCode = null ) : TNtfy =>
{
  return {
    ts: Date.now(),

    t: type,
    msg: msg,
    life: life,
    code: code,

    shown: false,
    seen: false,

    l: 0,
  }
} // TNtfyNew
