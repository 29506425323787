import React from 'react';

const Test: React.FC = (): React.JSX.Element => {
  return (
    <div>
      site surveys go here...
    </div>
  );
};

export default Test;
