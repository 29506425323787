import React, {useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';

const Modes = [
  {
    name: 'Days',
    index: 0,
  },
  {
    name: 'Weeks',
    index: 1,
  },
  {
    name: 'Months',
    index: 2,
  },
];

interface IModePickerProps {
  initialValue?: number;
  onChange?: (value: string) => void;

}

const ModePicker: React.FC<IModePickerProps> = ({initialValue, onChange}): React.JSX.Element => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (value: number) => {
    setValue(value);
    if (onChange) {
      onChange(value.toString());
    }
  };

  return (
    <>
      <FormControl size="small" fullWidth>
        <InputLabel id="mode-label">Mode</InputLabel>
        <Select
          value={value}
          labelId="mode-label"
          label="Mode"
          onChange={(event) => handleChange(event.target.value as number)}
        >
          {
            Modes.map((cur) => {
              return (
                <MenuItem key={cur.index} value={cur.index}>{cur.name}</MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
    </>
  );
}

export default ModePicker;
