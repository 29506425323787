import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import { TNtfyNew } from '../../_types/ntfy';
import { useDispatch } from 'react-redux';
import { NtfyReport } from '../../features/store/ntfy.slice';
import { IManifestFeature } from '../../_types/manifestfeature';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
  TablePagination
} from '@mui/material';
import { IFeatureDef } from "../../_types/featuredef";
import DeleteManifestFeatureDialog from '../DeleteManifestFeatureDialog/DeleteManifestFeatureDialog';
import NewManifestFeatureDialog from '../NewManifestFeatureDialog/NewManifestFeatureDialog';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import StyledTableRow from '../StyledTableRow/StyledTableRow';
import EditManifestFeatureDialog from '../EditManifestFeatureDialog/EditManifestFeatureDialog';
import { IManifestFeatureOut } from '../../_types/manifestfeatureout';
import { FetchContext } from '../../features/contexts/fetch';

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'Feature_Name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'NumberDays',
    numeric: true,
    disablePadding: true,
    label: 'Days',
  },
  {
    id: 'Mode',
    numeric: false,
    disablePadding: true,
    label: 'Mode',
  },
];

interface IManifestManifestDetailProps {
  equipmentSerialNumber: string;
}

const FeatureManifestDetail: React.FC<IManifestManifestDetailProps> = ({
  equipmentSerialNumber,
}): React.JSX.Element => {
  const [busy, setBusy] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [manifestFeatures, setManifestFeatures] = useState<IManifestFeature[]>([]);
  const [featureDefs, setFeatureDefs] = useState<IFeatureDef[]>([]);
  const [editManifestFeatureDialogOpen, setEditManifestFeatureDialogOpen] = React.useState(false);
  const [deleteManifestFeatureDialogOpen, setDeleteManifestFeatureDialogOpen] = React.useState(false);
  const [newManifestFeatureDialogOpen, setNewManifestFeatureDialogOpen] = React.useState(false);
  const [selectedManifestFeature, setSelectedManifestFeature] = React.useState({});
  const dispatch = useDispatch<any>();

  const fetchContext = useContext(FetchContext);

  useEffect(() => {
    //return () => {
      void (async () => {
        try {
          // @ts-ignore
          const { status, data } = await fetchContext.authAxios.post(
              '/Modules/AlignerUtilities.aspx/GetManifestFeatureListForUI',
              {
              }
          );

          if (status === 200) {
            const d = JSON.parse(data.d)
            setFeatureDefs(d);
          }
        } catch (err: any) {
          const notification = TNtfyNew(
            err.message,
            'error',
            'long',
          );

          dispatch(NtfyReport(notification));

          setFeatureDefs([]);
        }
      })();
    //}
  }, []);

  useEffect(() => {
    if (equipmentSerialNumber) {
      void (async () => {
        try {
          // @ts-ignore
          const { status, data } = await fetchContext.authAxios.post(
            '/Modules/AlignerUtilities.aspx/GetManifestFeatureDataByAlignerForUI',
            {
              sAlignerSerialNumber: equipmentSerialNumber,
            }
          );

          if (status === 200) {
            const d = JSON.parse(data.d).map((cur: IManifestFeature) => {
              // @ts-ignore
              cur['Feature_Name'] = featureDefs ? featureDefs.find(f => f.id === cur.Feature_Id)['Name'] : 'ERROR';
              cur['Mode'] = cur['Days0Weeks1Months2Mode'] === '0' ? 'Days' : cur['Days0Weeks1Months2Mode'] === '1' ? 'Weeks' : 'Months';

              return cur;
            }).sort((a: IManifestFeature, b: IManifestFeature) => {
              // @ts-ignore
              return a.Feature_Id > b.Feature_Id;
            });

            setManifestFeatures(d);
          }
        } catch (err: any) {
          const notification = TNtfyNew(
            err.message,
      'error',
       'long',
          );

          dispatch(NtfyReport(notification));

          setManifestFeatures([]);
       }
      })();
    } else {
      setManifestFeatures([]);
    }
  }, [equipmentSerialNumber]);

  const onEditClick = (feature: IManifestFeature) => {
    setSelectedManifestFeature(feature);
    setEditManifestFeatureDialogOpen(true);
  }

  const onDeleteClick = (feature: IManifestFeature) => {
    setSelectedManifestFeature(feature);
    setDeleteManifestFeatureDialogOpen(true);
  }

  const onNewClick = () => {
    setNewManifestFeatureDialogOpen(true);
  }

  const getFieldValue = (feature: IManifestFeature, field: string) => {
    const value = feature[field as keyof typeof feature]

    return (
      <>
        {value}
      </>
    )
  }

  const getTableRow = (feature: IManifestFeature) => {
    return (
      <>
        {
          headCells.map(headCell => (
            <TableCell key={`${feature.id}-${headCell.label}`}>
              {getFieldValue(feature, headCell.id)}
            </TableCell>
          ))
        }
        <TableCell>
          {
            fetchContext.isAdmin ? (
              <>
                <Button size="small" style={{marginRight:'10px'}} variant="outlined" onClick={() => onEditClick(feature)}>Edit</Button>
                <Button size="small" color="error" variant="outlined" onClick={() => onDeleteClick(feature)}>Delete</Button>
              </>
            ) : null
          }
        </TableCell>
      </>
    )
  }

  const handleDeleteManifestFeatureDialogClose = async (serialNumber: string, manifestFeature: IManifestFeature, cancel: boolean) => {
    if (!cancel && manifestFeature.Feature_Id) {
      setBusy(true);

      try {
        // @ts-ignore
        const { status, data } = await fetchContext.authAxios.post(
            '/Modules/AlignerUtilities.aspx/DeleteManifestRecordForUI',
            {
              iRecordID: manifestFeature.id,
            }
        );

        if (status === 200) {
          if (data.d.indexOf('Success') >= 0) {
            setManifestFeatures(manifestFeatures.filter(cur => cur.id !== manifestFeature.id));
          }
        }
      } catch(err: any) {
        console.log(`deleteManifestRecordForUI error: ${err.message}`);
      }
    }

    setBusy(false);
    setDeleteManifestFeatureDialogOpen(false);
  };

  const handleEditAdasSubscriptionDialogClose = async (serialNumber: string, manifestFeature: IManifestFeature, cancel: boolean) => {
    if (!cancel && manifestFeature.Feature_Id) {
      setBusy(true);

      try {
        // @ts-ignore
        manifestFeature['Feature_Name'] = featureDefs ? featureDefs.find(f => f.id === manifestFeature.Feature_Id)['Name'] : 'ERROR';
        manifestFeature['Mode'] = manifestFeature['Days0Weeks1Months2Mode'] === '0' ? 'Days' : manifestFeature['Days0Weeks1Months2Mode'] === '1' ? 'Weeks' : 'Months';

        const manifestFeatureOut: IManifestFeatureOut = {
          sAlignerSerialNumber: equipmentSerialNumber,
          iFeatureNumber: manifestFeature.Feature_Id as number,
          iNumberOfDays: manifestFeature.NumberDays as number,
          sMonth: null,
          sYear: null,
          iMode: parseInt(manifestFeature.Days0Weeks1Months2Mode as string),
        };

        // @ts-ignore
        const { status, data } = await fetchContext.authAxios.post(
            '/Modules/AlignerManifest.aspx/InsertOrUpdateFeature',
            manifestFeatureOut
        );

        if (status === 200) {
          if (data.d.indexOf('PASS') > 0) {
            const index = manifestFeatures.findIndex(cur => cur.Feature_Id === manifestFeature.Feature_Id);

            manifestFeatures[index] = manifestFeature;
            setManifestFeatures(manifestFeatures);
          }
        }
      } catch(err: any) {
        console.log(`insertOrUpdateFeature error: ${err.message}`);
      }
    }

    setBusy(false);
    setEditManifestFeatureDialogOpen(false);
  }

  const handleNewManifestFeatureDialogClose = async (serialNumber: string, manifestFeature: IManifestFeature, cancel: boolean) => {
    if (!cancel && manifestFeature.Feature_Id) {
      setBusy(true);

      try {
        // @ts-ignore
        manifestFeature['Feature_Name'] = featureDefs ? featureDefs.find(f => f.id === manifestFeature.Feature_Id)['Name'] : 'ERROR';
        manifestFeature['Mode'] = manifestFeature['Days0Weeks1Months2Mode'] === '0' ? 'Days' : manifestFeature['Days0Weeks1Months2Mode'] === '1' ? 'Weeks' : 'Months';

        const manifestFeatureOut: IManifestFeatureOut = {
          sAlignerSerialNumber: equipmentSerialNumber,
          iFeatureNumber: manifestFeature.Feature_Id as number,
          iNumberOfDays: manifestFeature.NumberDays as number,
          sMonth: null,
          sYear: null,
          iMode: parseInt(manifestFeature.Days0Weeks1Months2Mode as string),
        };

        // @ts-ignore
        const { status, data } = await fetchContext.authAxios.post(
            '/Modules/AlignerManifest.aspx/InsertOrUpdateFeature',
            manifestFeatureOut
        );

        if (status === 200) {
          if (data.d.indexOf('PASS') > 0) {
            const result = JSON.parse(data.d);
            manifestFeature.id = result.id;
            manifestFeatures.push(manifestFeature);
            setManifestFeatures(manifestFeatures);
          }
        }
      } catch(err: any) {
        console.log(`insertOrUpdateFeature error: ${err.message}`);
      }
    }

    setBusy(false);
    setNewManifestFeatureDialogOpen(false);
  }

  const getFilteredFeatureList = () => {
    return featureDefs.filter((cur) => {
      return !manifestFeatures.some(obj => obj.Feature_Id === cur.id);
    });
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{border:'1px solid blue',borderRadius:'5px',padding:'20px'}}>
      {
        (manifestFeatures.length > 0 || equipmentSerialNumber !== '') ? (
          <Grid container spacing={2}>
            <Typography variant="h6" align="left" sx={{width:'50%'}}>
              <div style={{margin: '10px 0 0 15px'}}>
                Features
              </div>
            </Typography>
            <Typography align="right" sx={{width:'50%'}}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                component="div"
                count={manifestFeatures.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {
                      headCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                          >
                            {headCell.label}
                          </TableCell>
                      ))
                    }
                    <TableCell>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    (rowsPerPage > 0
                      ? manifestFeatures.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : manifestFeatures
                    ).map((feature) => (
                      <StyledTableRow key={feature.id}>
                        {
                          getTableRow(feature)
                        }
                      </StyledTableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <Typography align="right" sx={{width:'100%'}}>
              {
                fetchContext.isAdmin ? (
                  <Button size="small" variant="outlined" style={{marginTop:'15px'}} onClick={() => onNewClick()}>New</Button>
                ) : null
              }
            </Typography>
          </Grid>
        ) : null
      }
      <EditManifestFeatureDialog
        busy={busy}
        equipmentSerialNumber={equipmentSerialNumber}
        manifestFeature={Object.assign({}, selectedManifestFeature)}
        open={editManifestFeatureDialogOpen}
        onClose={handleEditAdasSubscriptionDialogClose}
      />
      <DeleteManifestFeatureDialog
        busy={busy}
        equipmentSerialNumber={equipmentSerialNumber}
        manifestFeature={selectedManifestFeature}
        open={deleteManifestFeatureDialogOpen}
        onClose={handleDeleteManifestFeatureDialogClose}
      />
      <NewManifestFeatureDialog
        busy={busy}
        equipmentSerialNumber={equipmentSerialNumber}
        manifestFeature={{ NumberDays: 255, Days0Weeks1Months2Mode: '0' }}
        featureDefs={getFilteredFeatureList()}
        open={newManifestFeatureDialogOpen}
        onClose={handleNewManifestFeatureDialogClose}
      />
    </div>
  );
};

export default FeatureManifestDetail;
