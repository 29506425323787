import React, { useContext, useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FetchContext } from '../../features/contexts/fetch';

const Home = () => {
  const navigate = useNavigate();

  const fetchContext = useContext(FetchContext);

  useEffect(() => {
    void (async () => {
      try {
        // @ts-ignore
        const { status, data } = await fetchContext.authAxios.post(
          '/Modules/AlignerUtilities.aspx/GetManifestFeatureListForUI',
          {
          }
        );

        if (status === 200) {
          console.log('got date time from the server...');
        }
      } catch (err: any) {
        console.log('sad');
      }
    })();
  }, [fetchContext]);

  return (
    <div style={{padding:'30px'}}>
      <div style={{backgroundColor:'#D0D0D0', padding:'10px', borderRadius:'5px'}}>
        <Typography variant="h2">
          Aligner Web Portal
        </Typography>
        <Typography variant="body1">
          Cloud based aligner analytics...
        </Typography>
        <Button style={{marginTop:'8px'}} variant="contained" size="large" color="success" onClick={() => { navigate('/activate'); }}>
          Activate
        </Button>
      </div>
    </div>
  );
};

export default Home;
