import React, {useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';

const Months = [
  {
    name: 'January',
    index: 1,
  },
  {
    name: 'February',
    index: 2,
  },
  {
    name: 'March',
    index: 3,
  },
  {
    name: 'April',
    index: 4,
  },
  {
    name: 'May',
    index: 5,
  },
  {
    name: 'June',
    index: 6,
  },
  {
    name: 'July',
    index: 7,
  },
  {
    name: 'August',
    index: 8,
  },
  {
    name: 'September',
    index: 9,
  },
  {
    name: 'October',
    index: 10,
  },
  {
    name: 'November',
    index: 11,
  },
  {
    name: 'December',
    index: 12,
  },
];

interface IMonthPickerProps {
  initialValue?: number;
  onChange?: (value: number) => void;
}

const MonthPicker: React.FC<IMonthPickerProps> = ({initialValue, onChange}): React.JSX.Element => {
  const [value, setValue] = useState(initialValue || new Date().getMonth());

  const handleChange = (value: number) => {
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <>
      <FormControl size="small" fullWidth>
        <InputLabel id="month-label">Start Month</InputLabel>
        <Select
          value={value}
          labelId="month-label"
          label="Start Month"
          onChange={(event) => handleChange(event.target.value as number)}
        >
          {
            Months.map((cur) => {
              return (
                <MenuItem key={cur.index} value={cur.index}>{cur.name}</MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
    </>
  );
}

export default MonthPicker;
