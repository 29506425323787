import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import {IManifestFeature} from '../../_types/manifestfeature';
import DialogTransition from '../DialogTransition/DialogTansition';
import {LoadingButton} from '@mui/lab';

interface IDeleteManifestFeatureDialogProps {
  busy: boolean;
  equipmentSerialNumber: string;
  manifestFeature: IManifestFeature;
  open: boolean;
  onClose: (equipmentSerialNumber: string, manifestFeature: IManifestFeature, cancel: boolean) => void;
}

const DeleteManifestFeatureDialog: React.FC<IDeleteManifestFeatureDialogProps> = ({
  busy, equipmentSerialNumber, manifestFeature, open, onClose
}): React.JSX.Element => {
  const handleClose = (cancel: boolean) => {
    onClose(equipmentSerialNumber, manifestFeature, cancel);
  };

  return (
    <Dialog TransitionComponent={DialogTransition} open={open}>
      <DialogTitle>Delete {manifestFeature.Feature_Name || 'UNKNOWN'} for {equipmentSerialNumber}?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will delete the <b>{manifestFeature.Feature_Name || 'UNKNOWN'}</b> feature for the device {equipmentSerialNumber}.
        </DialogContentText>
        <DialogActions>
          <Button disabled={busy} onClick={() => handleClose(true)}>Cancel</Button>
          <LoadingButton loading={busy} color="error" onClick={() => handleClose(false)}>Delete</LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
};

export default DeleteManifestFeatureDialog;
