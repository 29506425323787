export const color = {
  accent: '#3498DB',
  clouds: '#EFEFEF',
  fonts: '#4C4C4D',
  info: '#3498DB',
  success: '#27AE60',
  warn: '#F1C40F',
  error: '#E74C3C',
};

