import axios from 'axios';

export const  getAlignerByIDForAlignerPage = (sAlignerSerialNumber: string, token: any): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    const options = {
      url: '/Modules/AlignerUtilities.aspx/GetAlignerByIDForAlignerPage',
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      data: {
        sAlignerSerialNumber,
      },
    };

    try {
      const res = await axios(options).then(response => response);
      if (res.status === 200) {
        const d = JSON.parse(res.data.d);
        if (d.length === 1) {
          resolve(d[0]);
        } else {
          reject(new Error('Equipment serial number not found.'));
        }
      }
    } catch (err) {
      reject(err);
    }
  });
}
