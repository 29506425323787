import React, {useState} from 'react';
import {FormControl, Slider, Typography} from '@mui/material';

interface IModeLengthPickerProps {
  initialValue?: number;
  onChange?: (value: number) => void;
}

const ModeLengthPicker: React.FC<IModeLengthPickerProps> = ({initialValue, onChange}): React.JSX.Element => {
  const [value, setValue] = useState<number>(initialValue as number);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
    if (onChange) {
      onChange(newValue as number);
    }
  };

  return (
    <>
      <Typography id="input-slider" gutterBottom>
        Mode Length
      </Typography>
      <FormControl size="small" fullWidth>
        <Slider
          size="small"
          defaultValue={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          step={1}
          min={1}
          max={255}
        />
      </FormControl>
    </>
  );
}

export default ModeLengthPicker;
