import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, TextField, Tooltip, tooltipClasses, TooltipProps
} from '@mui/material';
import DialogTransition from '../DialogTransition/DialogTansition';
import {LoadingButton} from '@mui/lab';
import PasswordComplexityHint from "../PasswordComplexityHint/PasswordComplexityHint";
import styled from "@emotion/styled";
import {hasThreeOfFour} from "../../utils/passwordcomplexity";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    border: '1px solid #dadde9',
  },
}));

interface IChangePasswordDialogProps {
  busy: boolean;
  userName: string;
  userId: string;
  open: boolean;
  onClose: (userId: string, newPassword: string, cancel: boolean) => void;
}

const ChangePasswordDialog: React.FC<IChangePasswordDialogProps > = ({
  busy, userName, userId, open, onClose
}): React.JSX.Element => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  useEffect(() => {
    if (!open) {
      setConfirmNewPassword('');
      setNewPassword('');
    }
  }, [open])

  const handleClose = (cancel: boolean) => {
    onClose(userId, newPassword, cancel);
  };

  const onNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewPassword(event.target.value);
  }

  const onConfirmNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConfirmNewPassword(event.target.value);
  }

  const canSavePassword = () => {
    return newPassword.length >= 12 && hasThreeOfFour(newPassword) && newPassword === confirmNewPassword;
  }

  return (
    <Dialog TransitionComponent={DialogTransition} open={open}>
      <DialogTitle>Change password for {userName}?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will change your password.  <i style={{color:'red'}}>Passwords must be 12 characters or greater in length, and must contain at least 3 of the following 4 types of characters: upper case, lower case, numbers, and special characters.</i>
        </DialogContentText>
        <div>
          <HtmlTooltip placement="top-end" title={<PasswordComplexityHint password={newPassword} />}>
            <TextField
              style={{width: '100%', margin: '20px 0 0 0'}}
              type="password"
              label="New Password"
              variant="outlined"
              value={newPassword}
              onChange={onNewPasswordChange}
              autoComplete="off"
            />
          </HtmlTooltip>
        </div>
        <div>
          <TextField
            style={{width: '100%', margin: '20px 0 0 0'}}
            id="outlined-basic"
            type="password"
            label="Confirm New Password"
            variant="outlined"
            value={confirmNewPassword}
            onChange={onConfirmNewPasswordChange}
            autoComplete="off"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={busy} onClick={() => handleClose(true)}>Cancel</Button>
        <LoadingButton loading={busy} disabled={!canSavePassword()} color="error" onClick={() => handleClose(false)}>Save</LoadingButton>
      </DialogActions>
    </Dialog>
  )
};

export default ChangePasswordDialog;
