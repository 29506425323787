/**
 * @copyright Snapon Equipment
 */

import React, { useState } from "react";

import {useSelector, useDispatch} from 'react-redux'
import { SnackbarContent, CustomContentProps, useSnackbar } from 'notistack'

import InfoIcon from '@mui/icons-material/Info';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import { Checkbox } from '@mui/material';

import { TNtfyType, TNtfyLife } from '../../../_types/ntfy';
import { color } from '../../../style-variables/color';

import {
  rdxNtfyTimeoutShort,
  rdxNtfyTimeoutLong,
  NtfyHideInfo,
} from "../../store/ntfy.slice";

import * as s from './popup.style'

/**
 */
function messageType( type: string | null ): string | null {
  if (type === "error") return color.error
  else if (type === "warn") return color.warn
  else if (type === "success") return color.success
  else if (type === "info") return color.accent
  else return null
}

/**
 */
interface ICompPopup extends CustomContentProps {
  type: TNtfyType,
  life: TNtfyLife;
}

/**
 */
export const CompPopup = React.forwardRef<HTMLDivElement, ICompPopup>((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  const dispatch = useDispatch()
  const timeoutShortNtfy = useSelector( rdxNtfyTimeoutShort )
  const timeoutLongNtfy = useSelector( rdxNtfyTimeoutLong )

  const [isCloseAvailable, IsCloseAvailable] = useState( false )
  const [isDontShowAgainAvailable, IsDontShowAgainAvailable] = useState( false )

  const {
    // You have access to notistack props and options 👇🏼
    id,
    message,

    // as well as your own custom props 👇🏼
    type,
    life,

    ...other
  } = props

  /**
   */
  React.useEffect( () => {
    IsCloseAvailable('click' === life)
    IsDontShowAgainAvailable('click' === life && 'info' === type)
  }, [] )

  /**
   */
  React.useEffect( () => {
    const time_out = 'short' === life
        ? 5*timeoutShortNtfy

        : 'long' === life
            ? 5*timeoutLongNtfy

            : 0

    if( time_out )
    {
      setTimeout( () => {
        closeSnackbar(id)
      }, time_out )
    } // if timeout
  }, [] )

  /**
   */
  const OnCheckedBox = React.useCallback( () => {
    dispatch( NtfyHideInfo(true) )
    closeSnackbar(id)
  }, [] )

  /**
   */
  const OnClose = React.useCallback( () => {
    closeSnackbar(id)
  }, [] )

  /**
   */
  return (
      <SnackbarContent ref={ref} role="alert" {...other}>
        <s.StyledToastContent>
          <s.IconArea bg={messageType( type ) || color.accent}>
            {
              type === "info"
                  ? <InfoIcon style={{ fontSize: '50px', color: color.clouds }} />

                  : type === "success"
                      ? <CheckCircleRoundedIcon style={{ fontSize: '50px', color: color.clouds }} />

                      : type === "warn"
                          ? <ReportProblemRoundedIcon style={{ fontSize: '50px', color: color.fonts }} />

                          : type === "error"
                              ?   <InfoIcon style={{ fontSize: '50px', color: color.clouds }} />

                              :   null
            }
          </s.IconArea>
          <s.ContentArea style={{ color: color.fonts, display:"flex", flexDirection: "column", alignItems: "flex-start", justifyContent: isDontShowAgainAvailable ? "space-between": "center" }}>
            <span style={{}}>{message}</span>
            <>
              {
                  isDontShowAgainAvailable &&
                  <div style={{ display: "flex", alignItems: "center", height: "20px" }}>
                    <Checkbox onChange={OnCheckedBox} />
                    Dont show this message again.
                  </div>
              }
            </>
          </s.ContentArea>
          <s.Tail>
            <s.Close>
              {isCloseAvailable && <CancelIcon onClick={OnClose} />}
            </s.Close>
          </s.Tail>
        </s.StyledToastContent>
      </SnackbarContent>
  )
})


