import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import DialogTransition from '../DialogTransition/DialogTansition';
import ManifestSpecPicker from '../ManifestSpecPicker/ManifestSpecPicker';
import ModeLengthPicker from '../ModeLengthPicker/ModeLengthPicker';
import ModePicker from '../ModePicker/ModePicker';
import {IManifestSpec} from '../../_types/manifestspec';
import MonthPicker from '../MonthPicker/MonthPicker';
import YearPicker from '../YearPicker/YearPicker';

interface INewManifestSpecDialogProps {
  busy: boolean;
  equipmentSerialNumber: string;
  manifestSpec: IManifestSpec,
  open: boolean;
  onClose: (equipmentSerialNumber: string, spec: IManifestSpec, cancel: boolean) => void;
}

const NewManifestSpecDialog: React.FC<INewManifestSpecDialogProps> = ({
  busy, equipmentSerialNumber, manifestSpec, open, onClose
}): React.JSX.Element => {
  const handleClose = (cancel: boolean) => {
    onClose(equipmentSerialNumber, manifestSpec, cancel);
  };

  const onDaysChange = (value: number) => {
    manifestSpec.NumberDays = value;
  }

  const onEndMonthChange = (value: number) => {
    manifestSpec.MonthIndex = value;
  }

  const onEndYearChange = (value: number) => {
    manifestSpec.YearIndex = value;
  }

  const onModeChange = (value: string) => {
    manifestSpec.Days0Weeks1Months2Mode = value;
  }

  return (
    <Dialog TransitionComponent={DialogTransition} open={open}>
      <DialogTitle>Edit specification {manifestSpec.Spec_Name} for {equipmentSerialNumber}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Edit the values for the {manifestSpec.Spec_Name} specifications.
        </DialogContentText>
        <div style={{margin:'15px 0 0 0'}}>
          <ModeLengthPicker initialValue={manifestSpec.NumberDays} onChange={onDaysChange}/>
        </div>
        <div style={{margin:'15px 0 0 0'}}>
          <MonthPicker initialValue={manifestSpec.MonthIndex} onChange={onEndMonthChange}/>
        </div>
        <div style={{margin:'15px 0 0 0'}}>
          <YearPicker initialValue={manifestSpec.YearIndex} onChange={onEndYearChange}/>
        </div>
        <div style={{margin:'15px 0 0 0'}}>
          <ModePicker initialValue={parseInt(manifestSpec.Days0Weeks1Months2Mode as string)} onChange={onModeChange}/>
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={busy} onClick={() => handleClose(true)}>Cancel</Button>
        <LoadingButton loading={busy} color="success" onClick={() => handleClose(false)}>Save</LoadingButton>
      </DialogActions>
    </Dialog>
  )
};

export default NewManifestSpecDialog;
