import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import { TNtfyNew } from '../../_types/ntfy';
import { useDispatch } from 'react-redux';
import { NtfyReport } from '../../features/store/ntfy.slice';
import { IAlignment } from '../../_types/alignment';
import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import StyledTableRow from '../StyledTableRow/StyledTableRow';
import { FetchContext } from '../../features/contexts/fetch';

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  width: number | string;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'BeginDateTime',
    numeric: false,
    disablePadding: true,
    label: 'Begin Time',
    width: 150,
  },
  /*
  {
    id: 'Technician',
    numeric: false,
    disablePadding: true,
    label: 'Technician',
    width: 100,
  },
  {
    id: 'ClampType',
    numeric: false,
    disablePadding: true,
    label: 'Clamp Type',
    width: 100,
  },
  {
    id: 'TargetType',
    numeric: true,
    disablePadding: true,
    label: 'Target Type',
    width: 100,
  },
  */
  {
    id: 'Year',
    numeric: false,
    disablePadding: true,
    label: 'Year',
    width: 100,
  },
  {
    id: 'Make',
    numeric: false,
    disablePadding: true,
    label: 'Make',
    width: 150,
  },
  {
    id: 'Model',
    numeric: false,
    disablePadding: true,
    label: 'Model',
    width: 150,
  },
  {
    id: 'Options',
    numeric: false,
    disablePadding: true,
    label: 'Options',
    width: 100,
  }
];

interface IAlignmentsTableProps {
  equipmentSerialNumber: string;
}

const AlignmentsTable: React.FC<IAlignmentsTableProps> = ({
  equipmentSerialNumber,
}): React.JSX.Element => {
  const [alignments, setAlignments] = useState<IAlignment[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [busy, setBusy] = useState(false);
  const dispatch = useDispatch<any>();

  const fetchContext = useContext(FetchContext);

  useEffect(() => {
    if (equipmentSerialNumber) {
      void (async () => {
        setBusy(true);
        setAlignments([]);

        try {
          // @ts-ignore
          const { data } = await fetchContext.authAxios.post(
            '/Modules/AlignerUtilities.aspx/GetAlignmentsforAlignmentPage',
            {
              sAlignerSerialNumber: equipmentSerialNumber,
            }
          );

          const d = JSON.parse(data.d);

          setAlignments(d);
          setPage(0);
        } catch (err: any) {
          const notification = TNtfyNew(
            err.message,
            'error',
            'long',
          );

          dispatch(NtfyReport(notification));
        }

        setBusy(false);
      })();
    } else {
      setAlignments([]);
    }
  }, [equipmentSerialNumber]);

  const getFieldValue = (alignment: IAlignment, field: string) => {
    const value = alignment[field as keyof typeof alignment];

    return (
      <>
        {value}
      </>
    );
  }

  const getTableRow = (alignment: IAlignment) => {
    return (
      <>
        {
          headCells.map(headCell => (
            <TableCell key={`${alignment.AlignerDataID}-${headCell.label}`}>
              {getFieldValue(alignment, headCell.id)}
            </TableCell>
          ))
        }
      </>
    );
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{border:'1px solid blue',borderRadius:'5px',padding:'20px'}}>
      {
        (alignments.length > 0) ? (
          <Grid container spacing={2}>
            <Typography variant="h6" align="left" sx={{width:'50%'}}>
              <div style={{margin: '10px 0 0 15px'}}>
                Alignments
              </div>
            </Typography>
            <Typography align="right" sx={{width:'50%'}}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                component="div"
                count={alignments.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {
                      headCells.map((headCell, index) => (
                        <TableCell
                          key={headCell.id}
                          style={{width: (index!==headCells.length - 1) ? headCell.width : undefined}}
                        >
                          {headCell.label}
                        </TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    (rowsPerPage > 0
                        ? alignments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : alignments
                    ).map((alignment) => (
                      <StyledTableRow key={alignment.AlignerDataID}>
                        {
                          getTableRow(alignment)
                        }
                      </StyledTableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : (
          <>
            { busy ? (
              <Typography align="center">
                <CircularProgress />
              </Typography>
              ) : null
            }
          </>
        )
      }
    </div>
  );
};

export default AlignmentsTable;
