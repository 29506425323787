import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import DialogTransition from '../DialogTransition/DialogTansition';
import ManifestFeaturePicker from '../ManifestFeaturePicker/ManifestFeaturePicker';
import ModeLengthPicker from '../ModeLengthPicker/ModeLengthPicker';
import ModePicker from '../ModePicker/ModePicker';
import {IFeatureDef} from '../../_types/featuredef';
import {IManifestFeature} from "../../_types/manifestfeature";

interface INewManifestFeatureDialogProps {
  busy: boolean;
  equipmentSerialNumber: string;
  manifestFeature: IManifestFeature,
  featureDefs: IFeatureDef[];
  open: boolean;
  onClose: (equipmentSerialNumber: string, feature: IManifestFeature, cancel: boolean) => void;
}

const NewManifestFeatureDialog: React.FC<INewManifestFeatureDialogProps> = ({
  busy, equipmentSerialNumber, manifestFeature, featureDefs, open, onClose
}): React.JSX.Element => {
  const [canCreate, setCanCreate] = useState<boolean>(false);

  useEffect(() => {
    checkCanCreate()
  }, [open]);

  const handleClose = (cancel: boolean) => {
    onClose(equipmentSerialNumber, manifestFeature, cancel);
  };

  const onFeatureChange = (value: number) => {
    manifestFeature.Feature_Id = value;
    checkCanCreate();
  };

  const onDaysChange = (value: number) => {
    manifestFeature.NumberDays = value;
    checkCanCreate();
  }

  const onModeChange = (value: string) => {
    manifestFeature.Days0Weeks1Months2Mode = value;
    checkCanCreate();
  }

  const checkCanCreate = () => {
    const result = (
      manifestFeature.Feature_Id !== undefined
      && manifestFeature.NumberDays !== undefined
      && manifestFeature.Days0Weeks1Months2Mode !== undefined
    );

    setCanCreate(result);
  }

  return (
    <Dialog TransitionComponent={DialogTransition} open={open}>
      <DialogTitle>New feature for {equipmentSerialNumber}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select the values for the new feature.
        </DialogContentText>
        <div style={{margin:'15px 0 0 0'}}>
          <ManifestFeaturePicker featureDefs={featureDefs} onChange={onFeatureChange}/>
        </div>
        <div style={{margin:'15px 0 0 0'}}>
          <ModeLengthPicker initialValue={manifestFeature.NumberDays} onChange={onDaysChange}/>
        </div>
        <div style={{margin:'15px 0 0 0'}}>
          <ModePicker initialValue={parseInt(manifestFeature.Days0Weeks1Months2Mode as string)} onChange={onModeChange}/>
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={busy} onClick={() => handleClose(true)}>Cancel</Button>
        <LoadingButton disabled={!canCreate} loading={busy} color="success" onClick={() => handleClose(false)}>Create</LoadingButton>
      </DialogActions>
    </Dialog>
  )
};

export default NewManifestFeatureDialog;
