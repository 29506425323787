const hasLowerCase = (str: string) => {
  return str.match(/[a-z]/);
}

const hasUpperCase = (str: string) => {
  return str.match(/[A-Z]/);
}

const hasNumber = (str: string) => {
  return str.match(/[0-9]/);
}

const hasSpecial = (str: string) => {
  return str.match(/[^a-zA-Z0-9]/);
}

const hasThreeOfFour = (str: string) => {
  let count = 0;
  count += hasLowerCase(str) ? 1 : 0;
  count += hasUpperCase(str) ? 1 : 0;
  count += hasNumber(str) ? 1 : 0;
  count += hasSpecial(str) ? 1 : 0;
  return count >= 3;
}

export {
  hasLowerCase,
  hasUpperCase,
  hasNumber,
  hasSpecial,
  hasThreeOfFour,
}
