import styled from '@emotion/styled';

export const StyledToastContent = styled("div")`
  width: 500px;
  height: 100px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: relative;
  margin-top: 75px;
`

export const IconArea = styled("div") <{ bg: string }>`
  width: 100px;
  height: 100px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  display: flex;
  align-items: center;
  background-color: ${props => props.bg};
  justify-content: center;
`

export const ContentArea = styled("div")`
  padding: 15px 0px 15px 15px ;
  height: 100px;
  box-sizing: border-box;
  background-color: white;
  flex: 1;

`
export const Tail = styled("div")`
  width: 50px;
  height: 100px;
  background-color: white;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;

`
export const Close = styled("div")`
  position: absolute;
  top: 10px;
  right: 15px;
`