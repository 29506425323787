import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import {IAdasSubscription} from '../../_types/adassubscription';
import {LoadingButton} from '@mui/lab';
import DialogTransition from '../DialogTransition/DialogTansition';
import MonthPicker from '../MonthPicker/MonthPicker';
import YearPicker from '../YearPicker/YearPicker';
import ModeLengthPicker from '../ModeLengthPicker/ModeLengthPicker';
import ModePicker from '../ModePicker/ModePicker';

interface IEditAdasSubscriptionDialogProps {
  busy: boolean;
  equipmentSerialNumber: string;
  subscription: IAdasSubscription;
  open: boolean;
  onClose: (equipmentSerialNumber: string, value: IAdasSubscription, cancel: boolean) => void;
}

const EditAdasSubscriptionDialog: React.FC<IEditAdasSubscriptionDialogProps> = ({
  busy, equipmentSerialNumber, subscription, open, onClose
}): React.JSX.Element => {
  const handleClose = (cancel: boolean) => {
    onClose(equipmentSerialNumber, subscription, cancel);
  };

  const onMonthChange = (value: number) => {
    subscription.MonthIndex = value;
  }

  const onYearChange = (value: number) => {
    subscription.YearIndex = value;
  }

  const onModeLengthChange = (value: number) => {
    subscription.NumberDays = value;
  }

  const onModeChange = (value: string) => {
    subscription.Days0Weeks1Months2Mode = value;
  }

  return (
    <Dialog TransitionComponent={DialogTransition} open={open}>
      <DialogTitle>Edit {subscription.Subscription_Name || 'UNKOWN'} for {equipmentSerialNumber}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Edit the values for the <b>{subscription.Subscription_Name || 'UNKOWN'}</b> subscription.
        </DialogContentText>
        <div style={{margin:'15px 0 0 0'}}>
          <MonthPicker initialValue={subscription.MonthIndex} onChange={onMonthChange}/>
        </div>
        <div style={{margin:'15px 0 0 0'}}>
          <YearPicker initialValue={subscription.YearIndex} onChange={onYearChange} />
        </div>
        <div style={{margin:'15px 0 0 0'}}>
          <ModeLengthPicker initialValue={subscription.NumberDays} onChange={onModeLengthChange}/>
        </div>
        <div style={{margin:'15px 0 0 0'}}>
          <ModePicker initialValue={parseInt(subscription.Days0Weeks1Months2Mode || '0')} onChange={onModeChange}/>
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={busy} onClick={() => handleClose(true)}>Cancel</Button>
        <LoadingButton loading={busy} color="success" onClick={() => handleClose(false)}>Save</LoadingButton>
      </DialogActions>
    </Dialog>
  )
};

export default EditAdasSubscriptionDialog;
