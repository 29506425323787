import React, { useState } from 'react';
import EquipmentDetail from '../../components/EquipmentDetail/EquipmentDetail';
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const Equipment = () => {
  const [serialNumber, setSerialNumber] = useState('');
  const [equipmentSerialNumber, setEquipmentSerialNumber] = useState('');

  const onEquipmentSerialNumberChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEquipmentSerialNumber(event.target.value.toUpperCase());
  }

  const onEquipmentSerialNumberKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (['Enter', 'NumpadEnter'].includes(event.key)) {
      setSerialNumber(equipmentSerialNumber);
    }
  }

  return (
    <div style={{margin: '35px 30px 0px 30px'}}>
      <TextField
        style={{minWidth:'300px'}}
        id="outlined-basic"
        label="Equipment Serial Number"
        variant="outlined"
        value={equipmentSerialNumber}
        onChange={onEquipmentSerialNumberChange}
        onKeyDown={onEquipmentSerialNumberKeyPress}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" color="primary" onClick={() => setSerialNumber(equipmentSerialNumber)}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Typography>
        <div style={{margin: '15px 0 30px 0'}}>
          <EquipmentDetail equipmentSerialNumber={serialNumber} />
        </div>
      </Typography>
    </div>
  );
}

export default Equipment;
