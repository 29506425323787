import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import DialogTransition from '../DialogTransition/DialogTansition';
import ManifestFeaturePicker from '../ManifestFeaturePicker/ManifestFeaturePicker';
import ModeLengthPicker from '../ModeLengthPicker/ModeLengthPicker';
import ModePicker from '../ModePicker/ModePicker';
import {IFeatureDef} from '../../_types/featuredef';
import {IManifestFeature} from "../../_types/manifestfeature";

interface IEditManifestFeatureDialogProps {
  busy: boolean;
  equipmentSerialNumber: string;
  manifestFeature: IManifestFeature,
  open: boolean;
  onClose: (equipmentSerialNumber: string, feature: IManifestFeature, cancel: boolean) => void;
}

const EditManifestFeatureDialog: React.FC<IEditManifestFeatureDialogProps> = ({
  busy, equipmentSerialNumber, manifestFeature, open, onClose
}): React.JSX.Element => {
  const handleClose = (cancel: boolean) => {
    onClose(equipmentSerialNumber, manifestFeature, cancel);
  };

  const onDaysChange = (value: number) => {
    manifestFeature.NumberDays = value;
  }

  const onModeChange = (value: string) => {
    manifestFeature.Days0Weeks1Months2Mode = value;
  }

  return (
    <Dialog TransitionComponent={DialogTransition} open={open}>
      <DialogTitle>Edit {manifestFeature.Feature_Name} for {equipmentSerialNumber}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Edit the values for the <b>{manifestFeature.Feature_Name || 'UNKOWN'}</b> feature.
        </DialogContentText>
        <div style={{margin:'15px 0 0 0'}}>
          <ModeLengthPicker initialValue={manifestFeature.NumberDays} onChange={onDaysChange}/>
        </div>
        <div style={{margin:'15px 0 0 0'}}>
          <ModePicker initialValue={parseInt(manifestFeature.Days0Weeks1Months2Mode as string)} onChange={onModeChange}/>
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={busy} onClick={() => handleClose(true)}>Cancel</Button>
        <LoadingButton loading={busy} color="success" onClick={() => handleClose(false)}>Save</LoadingButton>
      </DialogActions>
    </Dialog>
  )
};

export default EditManifestFeatureDialog;
