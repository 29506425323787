import React, { useContext, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import {
  Button,
  Divider,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from "@mui/material";
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from '../LoginButton/LoginButton';
import LogoutButton from '../LogoutButton/LogoutButton';
import ChangePasswordDialog from '../ChangePasswordDialog/ChangePasswordDialog';
import AddEquipmentDialog from '../AddEquipmentDialog/AddEquipmentDialog';
import EquipmentSearchDialog from '../EquipmentSearchDialog/EquipmentSearchDialog';
import { FetchContext } from '../../features/contexts/fetch';

import './MainHeaderBar.css';

interface IMainHeaderBarProps {
  pages: any[];
}

const MainHeaderBar: React.FC<IMainHeaderBarProps> = ({ pages }): React.JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
  const [addEquipmentDialogOpen, setAddEquipmentDialogOpen] = useState(false);
  const [searchEquipmentDialogOpen, setSearchEquipmentDialogOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const { isAuthenticated, user} = useAuth0();

  const fetchContext = useContext(FetchContext);

  const open = Boolean(anchorEl);

  const handleUserClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePasswordClick = () => {
    setAnchorEl(null);
    setChangePasswordDialogOpen(true);
  };

  const handleAddEquipmentClick = () => {
    setAnchorEl(null);
    setAddEquipmentDialogOpen(true);
  };

  const handleSearchEquipmentClick = () => {
    setAnchorEl(null);
    setSearchEquipmentDialogOpen(true);
  };

  const handleAddEquipmentDialogClose =  async (birthCertificateJson: object | null, cancel: boolean) => {
    if (!cancel && birthCertificateJson !== null) {
      setBusy(true);

      try {
        // @ts-ignore
        const { status } = await fetchContext.authAxios.post(
            'add_equipment',
            birthCertificateJson
        );

        if (status === 200) {
          setBusy(false);
          setAddEquipmentDialogOpen(false);
        }
      } catch(err: any) {
        setBusy(false);
      }
    } else {
      setAddEquipmentDialogOpen(false);
    }
  };

  const handleChangePasswordDialogClose =  async (userId: string, newPassword: string, cancel: boolean) => {
    if (!cancel && userId && newPassword) {
      setBusy(true);

      try {
        // @ts-ignore
        const { status } = await fetchContext.authAxios.post(
          'change_user_password',
          {
            userId,
            newPassword,
          }
        );

        if (status === 200) {
          setBusy(false);
          setChangePasswordDialogOpen(false);
        }
      } catch(err: any) {
        setBusy(false);
        console.log(`changeUserPassword error: ${err.message}`);
      }
    } else {
      setChangePasswordDialogOpen(false);
    }
  }

  const handleSearchEquipmentDialogClose = () => {
    setSearchEquipmentDialogOpen(false);
  }

  return (
    <AppBar position="static" sx={{ marginBottom: '15px'}}>
      <Toolbar disableGutters>
        <div id='title'>
          <Typography
            variant="h6"
            noWrap
          >
            Aligner Web Portal
          </Typography>
        </div>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Typography variant="button" display="block">
            {
              pages?.map((page:any, index) => {
                if (page.showLink && ((page.secure && isAuthenticated) || !page.secure))
                  return (<NavLink key={index} to={page.path}>{page.name}</NavLink>);
                else
                  return null;
              })
            }
          </Typography>
        </Box>
        <Box sx={{marginRight:'10px', display: { xs: 'none', md: 'flex' } }}>
          {
            !isAuthenticated ? (
              <LoginButton />
            ) : (
              <>
                <Button variant="contained" color="success" style={{margin:'0px 10px 0 0'}} onClick={handleUserClick}>
                  {user?.name || `I don't know who you are...`}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  {
                    fetchContext.canAdd ? (
                        <>
                          <MenuItem onClick={handleAddEquipmentClick}>Add Equipment</MenuItem>
                          <Divider variant="middle" />
                        </>
                    ) : null
                  }
                  <MenuItem onClick={handleSearchEquipmentClick}>Search Equipment</MenuItem>
                  <Divider variant="middle" />
                  <MenuItem onClick={handleChangePasswordClick}>Change Password</MenuItem>
                </Menu>
                <LogoutButton />
              </>
            )
          }
        </Box>
      </Toolbar>
      <ChangePasswordDialog
        busy={busy}
        userName={user?.name || ''}
        userId={user?.sub || ''}
        open={changePasswordDialogOpen}
        onClose={handleChangePasswordDialogClose}
      />
      <AddEquipmentDialog
        busy={busy}
        open={addEquipmentDialogOpen}
        onClose={handleAddEquipmentDialogClose}
      />
      <EquipmentSearchDialog
        open={searchEquipmentDialogOpen}
        onClose={handleSearchEquipmentDialogClose}
      />
    </AppBar>
  );
};

export default MainHeaderBar;
