import { combineReducers, configureStore } from '@reduxjs/toolkit';
import ntfySlice from '../features/store/ntfy.slice';

const combinedReducers = combineReducers({
  ntfy: ntfySlice,
});

export const store = configureStore({
  reducer: combinedReducers,
});

