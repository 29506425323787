import React, {useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';

const Years = [
  {
    name: '2020',
    index: 2020,
  },
  {
    name: '2021',
    index: 2021,
  },
  {
    name: '2022',
    index: 2022,
  },
  {
    name: '2023',
    index: 2023,
  },
  {
    name: '2024',
    index: 2024,
  },
  {
    name: '2025',
    index: 2025,
  },
  {
    name: '2026',
    index: 2026,
  },
  {
    name: '2027',
    index: 2027,
  },
  {
    name: '2028',
    index: 2028,
  },
  {
    name: '2029',
    index: 2029,
  },
  {
    name: '2030',
    index: 2030,
  },
  {
    name: '2031',
    index: 2031,
  },
];

interface IYearPickerProps {
  initialValue?: number;
  onChange?: (value: number) => void;
}

const YearPicker: React.FC<IYearPickerProps> = ({initialValue, onChange}): React.JSX.Element => {
  const [value, setValue] = useState(initialValue || new Date().getFullYear());

  const handleChange = (value: number) => {
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <>
      <FormControl size="small" fullWidth>
        <InputLabel id="year-label">Start Year</InputLabel>
        <Select
          value={value}
          labelId="year-label"
          label="Start Year"
          onChange={(event) => handleChange(event.target.value as number)}
        >
          {
            Years.map((cur) => {
              return (
                <MenuItem key={cur.index} value={cur.index}>{cur.name}</MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
    </>
  );
}

export default YearPicker;
